import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ReqGuardadoExamenes } from '../interfaces/guardado-examen-interface';

@Injectable({
  providedIn: 'root',
})
export class GuardadoExamenesService {
  constructor(
    private httpClient: HttpClient,
  ) {}

  postGuardarExamenes(idAgendamiento: string, req: ReqGuardadoExamenes): Observable<any> {
    const params =new
    HttpParams()
      .set('idAgendamiento', idAgendamiento)
    return this.httpClient.post<ReqGuardadoExamenes>(
      environment.baseURL + `ficha-clinica/guardar-examenes`,
      req, {params}
    );
  }
}
