import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { distinctUntilChanged, Subscription } from 'rxjs';
import { MultiSelectSearchFormFieldComponent } from '../../../../../shared/components/multi-select-search-form-field/multi-select-search-form-field.component';
import { IDataOperacion, IOperacionGuardado, IOperacionSelected } from '../../../../../shared/interfaces/operaciones.interface';
import { TabTratamientoService } from '../../../../../shared/services/tab-tratamiento.service';

@Component({
  selector: 'soph-operaciones',
  standalone: true,
  imports: [
    CommonModule,
    MultiSelectSearchFormFieldComponent,
    MatExpansionModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule
  ],
  templateUrl: './operaciones.component.html',
  styleUrl: './operaciones.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OperacionesComponent {
  formOperaciones: FormGroup;
  public operacionesOptions = signal<Array<IDataOperacion>>([]);
  operOptions: IDataOperacion[] = [];
  idsOperacionSelected: string[] = [];
  operacionesSelected: IOperacionSelected[] = [];
  operacionesGuardados: IOperacionGuardado[] = [];

  originalFormValues = signal<{ operaciones: string[] }>({
    operaciones: [],
  });

  subs: Subscription = new Subscription();

  get operaciones() {
    return this.formOperaciones.get('operaciones')!;
  }

  constructor(
    private fb: FormBuilder,
    private tabTratamientoService: TabTratamientoService
  ) {
    this.formOperaciones = this.fb.group({
      operaciones: [''],
    });

    this.operaciones?.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((values: string[]) => {
        if (values && values.length > 0) {
          this.onOperacionSelected(values);
          this.idsOperacionSelected = values;
        }
      });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
  ngOnInit(): void {
    this.llamarServicios();
  }

  setOriginalValues(operaciones: string[]) {
    this.originalFormValues.set({ operaciones });
  }

  llamarServicios() {
    this.tabTratamientoService.getOperaciones().subscribe((operResp) => {
      this.operacionesOptions.set(operResp.data);
      this.operOptions = operResp.data;
    });
  }
  showOperacionesGuardadss(data: string[]) {
    if(data && data.length > 0) {
      data.forEach((item) => {
        const operimiento = this.operacionesOptions().find(
          (o) => o.idOperacion === item
        );
        if (operimiento) {
          this.operacionesGuardados.push(operimiento);
        }
      });
    }
    this.resetForm();
  }
  editarOperaciones() {
    if(this.operacionesGuardados.length === 0) return;
    // Setear Valores originales en inputs
    this.formOperaciones.setValue(this.originalFormValues());
    // Vaciar medicamentos guardados
    this.operacionesGuardados = [];
  }
  resetForm() {
    this.operacionesSelected = [];
    this.idsOperacionSelected = [];
    this.formOperaciones.reset();
  }

  armarRequestOperaciones(): string[] {
    this.originalFormValues.set(this.formOperaciones.value);
    if(this.operaciones.value && this.operaciones.value.length > 0) {
      return this.operaciones.value;
    } else {
      return [];
    }
  }

  disableSelectorOperaciones() {
    this.operaciones.disable();
  }
  enableSelectorOperaciones() {
    this.operaciones.enable();
  }

  unselectOperacion(idTerapia: string) {
    const terapias = this.operaciones.value;
    const newTerapias: string[] = [];
    terapias.forEach((id: string) => {
      if (idTerapia != id) {
        newTerapias.push(id);
      }
    });
    this.operaciones.setValue(newTerapias);
  }

  onOperacionSelected(idsOperacionEmitidos: string[]) {
    this.operacionesSelected = [];
    if (idsOperacionEmitidos) {
      idsOperacionEmitidos.forEach((id) => {
        const oper = this.operOptions.find(
          (oper) => oper.idOperacion === id
        );
        if (oper) {
          this.operacionesSelected.push(oper);
        }
      });
    }
  }

}
