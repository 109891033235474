<mat-form-field
  [formGroup]="formGroup"
  class="estilo-input"
  appearance="outline"
  floatLabel="always"
  subscriptSizing="dynamic"
>
  <mat-icon matPrefix>search</mat-icon>

  <mat-label>{{ label }}</mat-label>

  <input [formControlName]="controlName" hidden />

  <input
    #inputSearch
    matInput
    [(ngModel)]="currentValue"
    [ngModelOptions]="{ standalone: true }"
    [placeholder]="placeholder"
    [required]="isRequired(controlName)"
    (blur)="updateErrorMessage(controlName)"
    [disabled]="formGroup.controls[controlName].disabled"
    [matChipInputFor]="chipGrid"
    [matAutocomplete]="auto"
    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
  />

  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" (closed)="onPanelClosed()">
    <mat-option *ngFor="let value of filteredValues()" [value]="value">
      {{ value[viewField] }}
    </mat-option>

  </mat-autocomplete>

  @if(data().length > 0) {
  <mat-divider></mat-divider>

  <span class="selected-values-title">{{ label }} seleccionadas:</span>
  }

  <mat-chip-grid #chipGrid aria-label="Value selection">
    @for (value of data(); track $index) {
    <mat-chip-row (removed)="remove(value)" class="chip-row">
      <span class="chip-content">{{ value[viewField] }}</span>

      @if(!formGroup.controls[controlName].disabled) {
      <button
        mat-icon-button
        matChipRemove
        [attr.aria-label]="'remove ' + value"
      >
        <mat-icon>close</mat-icon>
      </button>
      }
    </mat-chip-row>
    }
  </mat-chip-grid>

  <mat-icon matSuffix>arrow_drop_down</mat-icon>

  @if (isInvalid(controlName) && errorMessage()[controlName]) {
  <mat-error>{{ errorMessage()[controlName] }}</mat-error>
  }
</mat-form-field>
