import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, take, tap} from 'rxjs';
import {ReqRecuperarPassword} from "../../interfaces/recuperar-pass.interface";
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RecuperarPasswordService {
  private apiUrl = environment.baseURL + 'auth/recuperar-pass';
  constructor(private httpClient: HttpClient) { }

  postRecuperarPassword(clientId: string, email: string): Observable<any> {
    const params = new HttpParams()
      .set('client-id', clientId)
      .set('email', email);

    return this.httpClient.post(this.apiUrl, null, { params }).pipe(
      take(1),
      tap((response) => {
      })
    );
  }
}


