import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {IRespExistePaciente} from "../interfaces/exite-paciente.interface";
import {AuthenticationService} from "../../pages/services/authentication.service";
import {IResponseCentrosMedicos} from "../interfaces/centros-medicos.interface";
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ObtenerInstitucionesService {
  private apiUrl = environment.baseURL;

  constructor(private httpClient: HttpClient,
              private authService: AuthenticationService) {}

  getInstituciones(): Observable<IResponseCentrosMedicos> {
    return this.httpClient.get<IResponseCentrosMedicos>(this.apiUrl + 'datos-medicos/centros-medicos');
  }

}
