import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { ContainerChipComponent } from '../modal-history-360/components/container-chip/container-chip.component';
import { DialogRef } from '@angular/cdk/dialog';
import { Historial360 } from '../../../interfaces/historial-360.interface';
import { InformationChipComponent } from '../information-chip/information-chip.component';
import { MatDividerModule } from '@angular/material/divider';
import { IRespDataAtencion } from '../../interfaces/historicoConsulta.interface';
import { UtilServiceService } from '../../services/util-service.service';
import { ObtenerArchivosExamenesService } from '../../services/obtener-archivos-examenes.service';
import { MatIconButton } from '@angular/material/button';

@Component({
  selector: 'soph-modal-resumen-atencion',
  standalone: true,
  imports: [
    MatDialogModule,
    CommonModule,
    MatIcon,
    MatIconButton,
    ContainerChipComponent,
    MatDividerModule,
    InformationChipComponent,
  ],
  templateUrl: './modal-resumen-atencion.component.html',
  styleUrl: './modal-resumen-atencion.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalResumenAtencionComponent implements OnInit {
  public historialResult: Historial360 = inject(MAT_DIALOG_DATA);
  public atencionResult: IRespDataAtencion = inject(MAT_DIALOG_DATA);

  public readonly dialogRef = inject(DialogRef);
  public presecial = 'Presencial';
  public domiciliaria = 'Domiciliaria';
  public telemedicina = 'Telemedicina';
  public messageSinDatos = 'Sin datos registrados.';
  tipoAtencion!: string;
  estadoAtencion!: string;
  showEye: boolean = false;
  constructor(
    private readonly _utilService: UtilServiceService,
    private readonly _getArchivosService: ObtenerArchivosExamenesService
  ) {}
  ngOnInit(): void {
    this.atencionResult;
    this.getTipoAtencion();
  }

  getTipoAtencion() {
    this.tipoAtencion = this._utilService.formatUpperCaseFirstLetter(
      this.atencionResult.data.agendamiento.tipoAtencion
    );
  }
  getExamCargados(url: string) {
    this._getArchivosService.getArchivosCargados(url)
  }

  public capitalizeName(value: string): string {
    if (!value) return '';
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }
}
