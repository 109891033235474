import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FullCalendarModule } from '@fullcalendar/angular';
import { CalendarOptions, EventClickArg } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import esLocale from '@fullcalendar/core/locales/es';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import DateClickArg from '@fullcalendar/interaction';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { HeaderSophiaComponent } from '../../common/components/header-sophia/header-sophia.component';
import { ModalDetalleConsultaComponent } from '../../common/components/detalle-consulta/modal-detalle-consulta.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ModalBloqueoHorarioComponent } from '../../common/components/bloqueo-horario/modal-bloqueo-horario.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';
import { ObtenerAgendamientosMedicosService } from '../../../shared/services/obtener-agendamientos-medicos.service';
import { TipoEvento } from '../../../shared/interfaces/tratamiento/evento-agendamiento.interface';
import { Router } from '@angular/router';
import { InfoDetalleModalNuevaConsultaService } from '../../../shared/services/info-detalle-modal-nueva-consulta.service';
import {
  IDataDetalleNuevaConsulta,
  IRespDetalleNuevaConsulta,
} from '../../../shared/interfaces/tratamiento/detalle-nueva-consulta.interface';
@Component({
  selector: 'soph-calendario-agendamiento',
  standalone: true,
  imports: [
    FullCalendarModule,
    MatDialogModule,
    MatInputModule,
    HeaderSophiaComponent,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  templateUrl: './calendario-agendamiento.component.html',
  styleUrl: './calendario-agendamiento.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarioAgendamientoComponent {
  calendarOptions: CalendarOptions = {
    initialView: 'timeGridWeek',
    locale: esLocale,
    plugins: [timeGridPlugin, dayGridPlugin, DateClickArg, interactionPlugin],
    headerToolbar: {
      left: 'prev,next',
      center: 'title',
      right: 'timeGridDay,timeGridWeek,dayGridMonth',
    },
    slotLabelFormat: { hour: '2-digit', minute: '2-digit', hour12: false },
    dayHeaderFormat: { weekday: 'long', day: 'numeric' },
    contentHeight: 'auto',
    slotMinTime: '08:00:00',
    slotMaxTime: '21:00:00',
    selectable: true, // Permitir seleccionar tiempos
    selectMirror: true,
    dayMaxEvents: true,
    eventClick: this.openModalDetalleConsulta.bind(this),
    datesSet: this.setEventos.bind(this),
  };

  selected: Date | null = null;
  details: IRespDetalleNuevaConsulta | null = null;
  readonly dialog = inject(MatDialog);
  constructor(
    private readonly _eventosAgendamientosService: ObtenerAgendamientosMedicosService,
    private readonly _detalleConsulta: InfoDetalleModalNuevaConsultaService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  setEventos(dateInfo: any): void {
    const inicioFormateada = new Date(dateInfo.start)
      .toISOString()
      .split('T')[0];
    const terminoFormateada = new Date(dateInfo.end)
      .toISOString()
      .split('T')[0];
    const calendarApi = dateInfo.view.calendar;
    this._eventosAgendamientosService
      .getEventos(inicioFormateada, terminoFormateada)
      .subscribe({
        next: (res) => {
          if(res.data?.length === 0) return;

          calendarApi.removeAllEvents();

          res.data.forEach(evento => {
            calendarApi.addEvent({
              id: evento.id,
              title: evento.title,
              start: evento.start,
              end: evento.end,
              color: evento.color,
              tipo: evento.tipo,
              idAgendamiento: evento.id,
              metadata: evento.metadata,
            })
          })
        },
        error: (err) => {
          console.error('Error al obtener eventos', err);
        },
      });
  }
  openModalDetalleConsulta(clickInfo: EventClickArg) {
    const { tipo, idAgendamiento } = clickInfo.event.extendedProps;

    if (tipo === TipoEvento.Bloqueo || !idAgendamiento) return;

    this._detalleConsulta.getDetalleConsulta(idAgendamiento).subscribe({
      next: (res: IDataDetalleNuevaConsulta) => {
        if (res.success) {
          this.details = res.data;
          this.dialog.open(ModalDetalleConsultaComponent, {
            data: {
              fechaAtencion: this.details.fechaAtencion,
              horaAtencion: this.details.horaAtencion,
              tipoATencion: this.details.tipoATencion,
              nombrePaciente: this.details.nombrePaciente,
              telefonoPaciente: this.details.telefonoPaciente,
              detalleAtencion: this.details.detalleAtencion,
            },
          });
        }
      },
      error: (err) => {
        console.error('Error al obtener el detalle de consulta:', err);
      },
    });
  }

  openModalNuevaConsulta(): void {
    this.router.navigate(['/ingreso']);
  }

  public openBlockModal() {
    const dialogRef = this.dialog.open(ModalBloqueoHorarioComponent, {});
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }

  openBloqueoHorarios(): void {
    this.router.navigate(['/bloqueo-horario']);
  }
}
