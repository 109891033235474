import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { IReqTratamiento } from '../../interfaces/tratamiento/tratamiento.interfaces';

@Injectable({
  providedIn: 'root'
})
export class TratamientoService {

  private apiUrl = environment.baseURL+'ficha-clinica/tratamiento';

  constructor(private httpClient: HttpClient) { }

    postNuevoTratamiento(req: IReqTratamiento): Observable<any>{
      return this.httpClient.post<any>(this.apiUrl, req);
    }

    obtenerTratamiento(idAgendamiento: string): Observable<{data: IReqTratamiento}> {
      const params = new HttpParams().set('idAgendamiento', idAgendamiento);
      return this.httpClient.get<{data: IReqTratamiento}>(this.apiUrl, { params });
    }
}
