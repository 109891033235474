import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { AuthenticationService } from '../../pages/services/authentication.service';
import { Observable } from 'rxjs';
import { IRespDiagnosticos } from '../interfaces/diagnosticos.interface';

@Injectable({
  providedIn: 'root',
})
export class ServiceFormDiagnosticoService {
  private endpointUrlBase = `${environment.baseURL}datos-medicos`;

  constructor(
    private httpClient: HttpClient
  ) {}

  getDiagnosticos(): Observable<IRespDiagnosticos> {
    return this.httpClient.get<IRespDiagnosticos>(
      this.endpointUrlBase + '/diagnosticos',
    );
  }
}
