import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Observable} from 'rxjs';
import {IRespTipoDocumento} from "../interfaces/tipo-documento.interface";
import {AuthenticationService} from "../../pages/services/authentication.service";
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ObtenerTipoDocumentoService {
  private apiUrl = environment.baseURL + 'datos-medicos/tipos-documentos';

  constructor(private httpClient: HttpClient,
              private authService: AuthenticationService) { }

  getTipoDocumento(): Observable<IRespTipoDocumento> {
    return this.httpClient.get<IRespTipoDocumento>(this.apiUrl);
  }
}
