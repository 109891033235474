<form [formGroup]="form" (ngSubmit)="onSave(form)">
  <div class="container">
    <div class="full-width" style="padding-left: 10px">
      <h2>Exámenes</h2>
      <p>Busca y selecciona los exámenes que requieres del paciente</p>
    </div>
    <!-- Examenes -->
    <div class="full-width">
      <div class="input-container">
        <div class="input-half">
          <h2>Exámenes de laboratorio</h2>
          <soph-input-with-chip-form-field-v2
            [formGroup]="form"
            controlName="examenLaboratorio"
            label="Exámenes"
            placeholder="Seleccionar exámenes"
            [values]="examsLaboratorio"
            idField="idExamen"
            viewField="nombre"
          ></soph-input-with-chip-form-field-v2>
        </div>

        <div class="input-half">
          <h2>Exámenes de imágenes</h2>
          <soph-input-with-chip-form-field-v2
            [formGroup]="form"
            controlName="examenImagen"
            label="Exámenes"
            placeholder="Seleccionar exámenes"
            [values]="examsImagen"
            idField="idExamen"
            viewField="nombre"
          ></soph-input-with-chip-form-field-v2>
        </div>
      </div>

      <mat-divider></mat-divider>
      <div class="input-container">
        <div class="input-half">
          <p class="texto-info">Adjuntar exámenes</p>
          <p style="margin-bottom: 0">
            Aquí puedes adjuntar los exámenes entregados por el paciente
          </p>
          <soph-upload-archivos
            class=""
            [url]="this.documentUrl"
            [fileName]="fileName"
            [control]="form.controls.fileExam"
            [hasDocumentUrl]="hasDocumentUrl"
            (File)="onExamenSelected($event)"
            [isDisabled]="isUploaderDisabled"
            [examenesExistentes]="examenesExistentes"
          ></soph-upload-archivos>
        </div>

        <div class="input-half"></div>
      </div>
    </div>

    <!-- Diagnostico de los examenes -->
    <div class="full-width">
      <mat-divider></mat-divider>

      <div class="input-container">
        <div class="input-total">
          <p class="texto-info">Diagnóstico de los exámenes</p>
          <p>Describe tus observaciones con respecto a los exámenes</p>
          <soph-textarea-with-speech-to-text-form-field
            [control]="form.controls['diagnosticoExamen']"
            label="Comentarios"
            placeholder="Diagnóstico"
            [maxLength]="1000"
            [errorMessages]="{
              required: 'Este campo es obligatorio',
              maxlength: 'Ha excedido el número máximo de caracteres'
            }"
          >
          </soph-textarea-with-speech-to-text-form-field>
        </div>
      </div>
    </div>

    <!-- Acciones -->
    <div class="section-action">
      <div class="section-action__buttons">
        <button
          mat-stroked-button
          class="estilo-button"
          type="button"
          (click)="revertChanges()"
          [disabled]="!isFormEqual()"
        >
          Cancelar
        </button>
        <button
          mat-flat-button
          type="submit"
          [disabled]="isBtnGuardarDisabled()"
        >
          Guardar {{ isAllTabsCompleted() ? "y finalizar" : null }}
        </button>
      </div>
    </div>
  </div>
</form>
