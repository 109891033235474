import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { IDataDetalleNuevaConsulta } from '../interfaces/tratamiento/detalle-nueva-consulta.interface';

@Injectable({
  providedIn: 'root'
})
export class InfoDetalleModalNuevaConsultaService {
    private apiUrl = environment.baseURL + 'eventos';
  constructor(private httpClient: HttpClient) { }

    getDetalleConsulta(idAgendamiento: string): Observable<IDataDetalleNuevaConsulta> {
      return this.httpClient.get<IDataDetalleNuevaConsulta>(`${this.apiUrl}/${idAgendamiento}`);
    }
}
