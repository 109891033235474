import { Injectable } from '@angular/core';
import { Store } from './store';
import { FichaClinicaState } from './ficha-clinica.model';
import { MEDICAL_RECORD_KEY } from '../../constants';

const initialState: FichaClinicaState = {
  idAgendamiento: null,
  idPaciente: null,
  estado: null,
  historial: {
    identificacion: false,
    anamnesis: false,
    diagnostico: false,
    tratamiento: true,
    examenes: false,
  },
  tabActualSeleccionado: 0,
  fechaAtencion: '',
  horaAtencion: '',
  tipoAtencion: '',
};

@Injectable({
  providedIn: 'root',
})
export class FichaClinicaStore extends Store<FichaClinicaState> {
  get values(): FichaClinicaState {
    return this.state;
  }

  constructor() {
    const localValues = localStorage.getItem(MEDICAL_RECORD_KEY)
      ? (JSON.parse(
          localStorage.getItem(MEDICAL_RECORD_KEY)!
        ) as FichaClinicaState)
      : null;

    let newValues: FichaClinicaState = {
      ...initialState,
    };

    if (!localValues) {
      localStorage.setItem(MEDICAL_RECORD_KEY, JSON.stringify(initialState));
    }

    if (localValues) {
      newValues = {
        ...initialState,
        ...localValues,
      };
    }

    super(newValues);
  }

  newStore(newValue: Partial<FichaClinicaState>) {
    const data = {
      ...initialState,
      ...newValue,
    };
    this.setState(data);
    localStorage.setItem(MEDICAL_RECORD_KEY, JSON.stringify(data));
  }

  updateStore(newValue: FichaClinicaState) {
    this.setState(newValue);
    localStorage.setItem(MEDICAL_RECORD_KEY, JSON.stringify(newValue));
  }

  updateMedicalHistoryBySection(
    name: keyof FichaClinicaState['historial'],
    value: boolean
  ) {
    this.updateStore({
      ...this.values,
      historial: {
        ...this.values.historial,
        [name]: value,
      },
    });
  }
}
