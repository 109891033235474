import { ChangeDetectionStrategy, model, Component, ChangeDetectorRef, effect, OnInit, OnDestroy,} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import { MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {CommonModule, JsonPipe, NgIf, registerLocaleData} from '@angular/common';
import { FormsModule, Validators  } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {MatSelect, MatSelectModule} from "@angular/material/select";
import { IDataPacienteForm } from '../../../../shared/interfaces/paciente-form.interface';
import { IDataCentrosMedico } from '../../../../shared/interfaces/centros-medicos.interface';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ObtenerInstitucionesService } from '../../../../shared/services/obtener-instituciones.service';
import { IdDireccionSeleccionadaSignalService } from '../../../../shared/services/id-direccion-seleccionada-signal.service';
import { ShareDataService } from '../../../../shared/services/share-data.service';
import { FichaClinicaStore } from '../../../../shared/stores/ficha-clinica/ficha-clinica.store';
import { AgendamientosService } from '../../../services/agendamientos.service';
import { ModalErrorGeneralComponent } from '../../modal-error-general/modal-error-general.component';
import { ReqNuevoAgendamiento } from '../../../../shared/interfaces/nueva-consulta.interface';
import { EstadoFichaClinica } from '../../../../shared/enums';
import { ModalNuevoAgendamientoCreadoComponent } from '../../../../shared/components/modal-nuevo-agendamiento-creado/modal-nuevo-agendamiento-creado.component';
import { ErrorMessages } from '../../../../shared/interfaces/errorMesagges.interface';
import { HeaderSophiaComponent } from '../header-sophia/header-sophia.component';
import { MatCardModule } from '@angular/material/card';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule, provideNativeDateAdapter } from '@angular/material/core';
import { SelectFormFieldComponent } from '../../../../shared/components/select-form-field/select-form-field.component';
import { CustomDateAdapter } from '../nuevo-agendamiento/ingreso-hora-fecha/ingreso-hora-fecha.component';
import localeEs from '@angular/common/locales/es-CL';
registerLocaleData(localeEs);
interface ISelectOption {
  value: string;
}

@Component({
  selector: 'soph-modal-bloqueo-horario',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    NgIf,
    HeaderSophiaComponent,
    MatCardModule,
    MatAutocompleteModule,
    MatCardModule,
    MatDatepickerModule,
    SelectFormFieldComponent,
    MatNativeDateModule,
  ],
    providers: [
      provideNativeDateAdapter(),
      { provide: MAT_DATE_LOCALE, useValue: 'es-CL' },
      { provide: DateAdapter, useClass: CustomDateAdapter },
    ],
  templateUrl: './modal-bloqueo-horario.component.html',
  styleUrl: './modal-bloqueo-horario.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})


export class ModalBloqueoHorarioComponent implements OnInit, OnDestroy {
  /*GET CAMPOS FORM*/
  get horaInicio(): FormControl {
    return this.formHoraFecha.get('horaInicio') as FormControl;
  }
  get horaTermino(): FormControl {
    return this.formHoraFecha.get('horaTermino') as FormControl;
  }
  get motivo(): FormControl {
    return this.formHoraFecha.get('motivo') as FormControl;
  }
  get star(): FormControl {
    return this.formHoraFecha.get('start') as FormControl;
  }
  get end(): FormControl {
    return this.formHoraFecha.get('end') as FormControl;
  }
  listDirecciones: any;
  horaSelectOptions: ISelectOption[] = [];
  /*selected = model<Date | null>(null);*/
  selected: Date | null = null;

  dateRange: { start: Date | null; end: Date | null } = { start: null, end: null }
  isSelectingStart: boolean = true;
  nuevoAgendamiento: any;
  minDate: Date = new Date();
  formHoraFecha: FormGroup;
  idPaciente: any;
  idAgendamiento: any;
  subs = new Subscription();
  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private router: Router,
    private _agendamientos: AgendamientosService
  ) {
    effect(() => {
    });
    this.formHoraFecha = this.fb.group({
      fechaSeleccionada: ['', Validators.required],
      start: [null, Validators.required],
      end: [null, Validators.required],
      horaInicio: ['', Validators.required],
      horaTermino: ['', Validators.required],
      motivo: ['', Validators.required]
    });
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    this.rangoHorario();
  }

  onDateChange(date: Date): void {
    if (this.isSelectingStart) {
      // Selecciona la fecha de inicio.
      this.dateRange.start = date;
      this.dateRange.end = null; // Resetea la fecha de término.
    } else {
      // Selecciona la fecha de término.
      this.dateRange.end = date;

      // Valida el rango de fechas.
      if (this.dateRange.start && this.dateRange.end && this.dateRange.start > this.dateRange.end) {
        const temp = this.dateRange.start;
        this.dateRange.start = this.dateRange.end;
        this.dateRange.end = temp;
      }

      // Actualiza el formulario con las fechas seleccionadas.
      this.formHoraFecha.patchValue({
        start: this.dateRange.start,
        end: this.dateRange.end,
      });
    }

    // Alterna entre seleccionar inicio y término.
    this.isSelectingStart = !this.isSelectingStart;
    this.cdr.markForCheck(); // Notifica cambios a la vista.
  }
  

  public rangoHorario() {
    this._agendamientos.getRangoHora().subscribe({
      next: (resp) => {
        this.horaSelectOptions = resp.data.map((hora: string) => ({
          value: hora,
        }));
      },
      error: () => {
        this.dialog.open(ModalErrorGeneralComponent); // Muestra el modal de error
      },
    });
  }



  async onSubmit() {
    if (this.formHoraFecha.valid) {
    } else {
      return;
    }
  }


  public volverACalendario() {
    return this.router.navigate(['/calendario'])
  }
}

