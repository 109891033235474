<button
  (click)="dialogRef.close()"
  tabindex="-1"
  mat-icon-button
  matTooltip="Cerrar"
  aria-label="icon-button close"
>
  <mat-icon>close</mat-icon>
</button>
<div mat-dialog-tittle>
  <h3 style="padding-left: 50px">Paciente</h3>
  <section class="history-360__header">
    <div class="history-360__header--div1">
      <img src="../../../../assets/icons/Avatar.svg" height="53" width="53" />
      <div>
        <p>Nombre: <span>{{atencionResult.data.paciente ? atencionResult.data.paciente.nombrePaciente : messageSinDatos}}</span></p>
        <p>Historia clínica: <span>{{ atencionResult.data.paciente ? atencionResult.data.paciente.numeroDocumento : messageSinDatos}}</span></p>
      </div>
    </div>
    <div class="history-360__header--div2">
      <img
        src="../../../../assets/icons/Calendar.svg"
        height="20"
        width="20"
        alt="Logo"
        style="    padding-top: 4px;"
      />
      <label style="margin-left: 8px;"
        >Fecha de la atención:
        <span>{{ atencionResult.data.agendamiento.fechaAtencion }}</span></label
      >

      <div
        class="chip-tipo-consulta"
        [ngClass]="{
          presencial: tipoAtencion === presecial,
          telemedicina: tipoAtencion === telemedicina,
          domiciliaria: tipoAtencion === domiciliaria
        }"
      >
        <div>
          <img
            src="assets/icons/house.svg"
            style="display: table-cell"
            *ngIf="tipoAtencion === domiciliaria"
            alt="house"
          />
        </div>
        <div>
          <img
            src="assets/icons/hospital.svg"
            style="display: table-cell"
            *ngIf="tipoAtencion === presecial"
            alt="hospital"
          />
        </div>
        <div>
          <img
            src="assets/icons/telemedicina.svg"
            style="display: table-cell"
            *ngIf="tipoAtencion === telemedicina"
            alt="telemedicina"
          />
        </div>
        <p class="style-texto-chip" style="margin-bottom: 0">
          {{ tipoAtencion }}
        </p>
      </div>
    </div>
  </section>

  <section class="history-360__bread">
    <div class="history-360__bread--blue">
      <label>Historial de consulta</label>
    </div>
  </section>
</div>

<mat-dialog-content>
  <section class="history-360">
    <section class="history-360__text">
      <p>
        Para garantizar la integridad de la información, solo podrás visualizar
        los datos registrados en la consulta.
      </p>
    </section>
    <mat-divider></mat-divider>
    <div class="history-360__section-datos-paciente">
      <div class="history-360__header--divRef">
        <label>Peso (Kg) <span></span></label>
        <p>{{ atencionResult.data.peso ?  atencionResult.data.peso : '-' }}</p>
      </div>
      <div class="history-360__header--divRef">
        <label>Altura <span></span></label>
        <p>{{ atencionResult.data.altura ? atencionResult.data.altura  : '-'  }}</p>
      </div>
      <div class="history-360__header--divRef">
        <label>Grupo sanguíneo<span></span></label>
        <p>{{ atencionResult.data.grupoSanguineo  ?  atencionResult.data.grupoSanguineo : '-' }}</p>
      </div>
      <div class="history-360__header--divRef">
        <label>Temperatura<span></span></label>
        <p>{{ atencionResult.data.anamnesis.signosVitales.temperatura ? 
        atencionResult.data.anamnesis.signosVitales.temperatura : '-'}}</p>
      </div>
      <div class="history-360__header--divRef">
        <label>Saturación<span></span></label>
        <p>{{ atencionResult.data.anamnesis.signosVitales.saturacion ? 
        atencionResult.data.anamnesis.signosVitales.saturacion : '-'}} %</p>
      </div>
    </div>
    <div class="history-360__section-datos-paciente-2">
      <div class="history-360__header--divRef">
        <label>Frecuencia cardiaca (ppm)<span></span></label>
        <p>
          {{ atencionResult.data.anamnesis.signosVitales.frecuenciaCardiaca ?
           atencionResult.data.anamnesis.signosVitales.frecuenciaCardiaca : '-'
           }} ppm
        </p>
      </div>
      <div class="history-360__header--divRef">
        <label>Presión arterial (mmHg)<span></span></label>
        <p>{{ atencionResult.data.anamnesis.signosVitales.presionArterial ? 
        atencionResult.data.anamnesis.signosVitales.presionArterial : '-' }} mmHg</p>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="contenedor-info-acom" style="margin: 20px 0 20px 0">
      <h3 style="margin-bottom: 12px">Datos del acompañante:</h3>
      <section class="history-360__header" style="padding: 0">
        <div class="history-360__header--div1">
          <img src="assets/icons/Avatar.svg" height="53" width="53" />

          <div *ngIf="!atencionResult.data.acompanante">
            <p>Sin datos registrados</p>
          </div>
          <div *ngIf="atencionResult.data.acompanante">
            <p>
              Nombre:
              <span>{{
                atencionResult.data.acompanante
                  ? atencionResult.data.acompanante.nombreCompleto
                  : messageSinDatos
              }}</span>
            </p>
            <p>
              Documento:
              <span>{{
                atencionResult.data.acompanante
                  ? atencionResult.data.acompanante.tipoNumDocumento
                  : messageSinDatos
              }}</span>
            </p>
            <p>
              Parentesco:
              <span>{{
                atencionResult.data.acompanante
                  ? atencionResult.data.acompanante.patentesco
                  : messageSinDatos
              }}</span>
            </p>
            <p>
              Edad:
              <span>{{
                atencionResult.data.acompanante
                  ? atencionResult.data.acompanante.edad
                  : messageSinDatos
              }}</span>
            </p>
            <p>
              Teléfono:
              <span>{{
                atencionResult.data.acompanante
                  ? atencionResult.data.acompanante.telefono
                  : messageSinDatos
              }}</span>
            </p>
          </div>
        </div>
        <div class="history-360__header--divRef">
          <label>Referido</label>
          <p>
            {{
              atencionResult.data.anamnesis.especialidad
                ? atencionResult.data.anamnesis.especialidad
                : messageSinDatos
            }}
          </p>
        </div>
      </section>
    </div>
    <mat-divider></mat-divider>

    <section class="history-360__chip">
      <div class="history-360__chip--container">
        <soph-container-chip>
          <div class="sop-title" sopTitle>
            <label class="title">Alergias:</label>
          </div>
          @if (atencionResult.data.anamnesis &&
          atencionResult.data.anamnesis.alergias) {
          <ng-container sopChip>
            @for (alergia of atencionResult.data.anamnesis.alergias; track
            $index) {
            <soph-information-chip [info]="alergia" />
            }
          </ng-container>
          }
        </soph-container-chip>

        <soph-container-chip>
          <div class="sop-title" sopTitle>
            <label class="title">Enfermedades pre-existentes:</label>
          </div>
          @if (atencionResult.data.anamnesis && atencionResult.data.anamnesis.enfermedades) {
            <ng-container sopChip>
              @if (atencionResult.data.anamnesis.enfermedades.length > 0) {
                @for (enfermedad of atencionResult.data.anamnesis.enfermedades; track $index) {
                  <soph-information-chip [info]="enfermedad"></soph-information-chip>
                }
              } @else {
                <p class="text-area">{{messageSinDatos}}</p>
              }
            </ng-container>
          } @else {
            <p class="text-area">{{messageSinDatos}}</p>
          }
        </soph-container-chip>
        

        <soph-container-chip>
          <div class="sop-title" sopTitle>
            <label class="title">Cirugías:</label>
          </div>
          @if (atencionResult.data.anamnesis && atencionResult.data.anamnesis.cirugias) {
            <ng-container sopChip>
              @if (atencionResult.data.anamnesis.cirugias.length > 0) {
                @for (cirugia of atencionResult.data.anamnesis.cirugias; track $index) {
                  <soph-information-chip [info]="cirugia"></soph-information-chip>
                }
              } @else {
                <p class="text-area">{{messageSinDatos}}</p>
              }
            </ng-container>
          } @else {
            <p class="text-area">{{messageSinDatos}}</p>
          }
        </soph-container-chip>
        

        <soph-container-chip>
          <div class="sop-title" sopTitle>
            <label class="title">Medicamentos de uso frecuente:</label>
          </div>
          @if (atencionResult.data.anamnesis && 
          atencionResult.data.anamnesis.medicamentos) {
            <ng-container sopChip>
              @if (atencionResult.data.anamnesis.medicamentos.length > 0) {
                @for (medicamento of atencionResult.data.anamnesis.medicamentos; track $index) {
                  <soph-information-chip [info]="medicamento"></soph-information-chip>
                }
              } @else {
                <p class="text-area">{{messageSinDatos}}</p>
              }
            </ng-container>
          } @else {
            <p class="text-area">{{messageSinDatos}}</p>
          }
        </soph-container-chip>
        
        <soph-container-chip>
          <div class="sop-title" sopTitle >
            <label class="title">Diagnóstico:</label>
            <label>{{' ' + capitalizeName(atencionResult.data.diagnosticos.tipoDiagnostico)}}</label>
          
          </div>
          @if (atencionResult.data.diagnosticos) {
          <ng-container sopChip>
            @for ( diagnosticos of
            atencionResult.data.diagnosticos.diagnosticos; track $index ) {
            <soph-information-chip [info]="diagnosticos" />
            }
          </ng-container>
          }
        </soph-container-chip>

        <soph-container-chip>
          <div class="sop-title" sopTitle>
            <label class="title">Terapias seleccionadas:</label>
          </div>
          @if (atencionResult.data.tratamientos &&
          atencionResult.data.tratamientos.terapias) {
          <ng-container sopChip>
            @if (atencionResult.data.tratamientos.terapias.length > 0) { @for
            (terapias of atencionResult.data.tratamientos.terapias; track
            $index) {
            <soph-information-chip [info]="terapias"></soph-information-chip>
            } } @else {
            <p class="text-area">{{messageSinDatos}}</p>
            }
          </ng-container>
          } @else {
          <p class="text-area">{{messageSinDatos}}</p>
          }
        </soph-container-chip>

        <soph-container-chip>
          <div class="sop-title" sopTitle>
            <label class="title">Procedimientos seleccionados:</label>
          </div>
          @if (atencionResult.data.tratamientos &&
          atencionResult.data.tratamientos.procedimientos) {
          <ng-container sopChip>
            @if (atencionResult.data.tratamientos.procedimientos.length > 0) {
            @for (procedimientos of
            atencionResult.data.tratamientos.procedimientos; track $index) {
            <soph-information-chip
              [info]="procedimientos"
            ></soph-information-chip>
            } } @else {
            <p class="text-area">{{messageSinDatos}}</p>
            }
          </ng-container>
          } @else {
          <p class="text-area">{{messageSinDatos}}</p>
          }
        </soph-container-chip>

        <soph-container-chip>
          <div class="sop-title" sopTitle>
            <label class="title">Cirugías seleccionadas:</label>
          </div>
          @if (atencionResult.data.tratamientos && atencionResult.data.tratamientos.operaciones) {
            <ng-container sopChip>
              @if (atencionResult.data.tratamientos.operaciones.length > 0) {
                @for (operaciones of atencionResult.data.tratamientos.operaciones; track $index) {
                  <soph-information-chip [info]="operaciones"></soph-information-chip>
                }
              } @else {
                <p class="text-area">{{messageSinDatos}}</p>
              }
            </ng-container>
          } @else {
            <p class="text-area">{{messageSinDatos}}</p>
          }
        </soph-container-chip>
        
        <soph-container-chip>
          <div class="sop-title" sopTitle>
            <label class="title">Examenes seleccionados:</label>
          </div>
          @if (atencionResult.data.examenes) {
            <ng-container sopChip>
              @if (atencionResult.data.examenes.length > 0) {
                @for (examenes of atencionResult.data.examenes; track $index) {
                  <soph-information-chip [info]="examenes"></soph-information-chip>
                }
              } @else {
                <p class="text-area">{{messageSinDatos}}</p>
              }
            </ng-container>
          } @else {
            <p class="text-area">{{messageSinDatos}}</p>
          }
        </soph-container-chip>
        
        <div class="upload-file">
          <div class="sop-title" sopTitle>
            <label class="title">Exámenes adjuntos:</label>
            <div *ngIf="atencionResult.data.examenesDescargables" class="container-examenes">
              @for (examen of
            atencionResult.data.examenesDescargables ; track $index) {
            <div class="chip-examenes">
              <p class="text-chip-examenes">{{ examen.nombreArchivo }}</p>
              <img
                src="assets/icons/eye_upload.svg"
                class="icon card-icon-eye"
                (click)="getExamCargados(examen.url)"
                (mouseover)="showEye = true"
                (mouseleave)="showEye = false"
              />
            </div>
            } 
            </div>

            <p *ngIf="!atencionResult.data.examenesDescargables" class="text-area" style="margin-top: 10px;">{{ messageSinDatos }}</p>
          </div>
        </div>
      </div>

      <div class="container-anamnesis">
        <p style="font-weight: 700">Antecedentes familiares:</p>
        <p class="text-area">
          {{ atencionResult.data.anamnesis.antecedentes ? 
          atencionResult.data.anamnesis.antecedentes : messageSinDatos}}
        </p>
      </div>

      <div class="container-anamnesis">
        <p style="font-weight: 700">Relato:</p>
        <p class="text-area">{{ atencionResult.data.anamnesis.relato  ? 
        atencionResult.data.anamnesis.relato : messageSinDatos}}</p>
      </div>

      <div class="container-anamnesis">
        <p style="font-weight: 700">Estilo de vida:</p>
        <p class="text-area">{{ atencionResult.data.anamnesis.estiloVida ?
        atencionResult.data.anamnesis.estiloVida  :  messageSinDatos }}</p>
      </div>

      <div class="container-anamnesis">
        <p style="font-weight: 700">Indicaciones extra:</p>
        <p class="text-area">
          {{
            (atencionResult.data.tratamientos &&
              atencionResult.data.tratamientos.indicaciones) ?
              (atencionResult.data.tratamientos &&
              atencionResult.data.tratamientos.indicaciones) : messageSinDatos
          }}
        </p>
      </div>

      <div class="container-anamnesis">
        <p style="font-weight: 700">Diagnóstico exámenes:</p>
        <p class="text-area">
          {{ atencionResult.data.diagnosticoExamenes ?
           atencionResult.data.diagnosticoExamenes : messageSinDatos}}
        </p>
      </div>

      <section class="history-360__sign">
        <img src="assets/images/Logo.svg" height="30" width="115" alt="Logo" />
        <label class="history-360__sign--doc-name">Daniel Carvalo</label>
        <div class="history-360__sign--container">
          <label>DNI: 96522155</label>
          <div class="div-vertical"></div>
          <label>CMP: 0944300 </label>
        </div>
        <div class="div-horiz"></div>

        <label class="history-360__sign--specialist"
          >Ginecología y obstetricia</label
        >
      </section>
    </section>
  </section>
</mat-dialog-content>
