import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject} from '@angular/core';
import {AbstractControl,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
  AsyncValidatorFn} from "@angular/forms"
import {MatFormFieldModule} from "@angular/material/form-field";
import {Auth, fetchSignInMethodsForEmail } from '@angular/fire/auth';
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {CommonModule} from "@angular/common";
import {Router} from "@angular/router";
import {RecuperarPasswordService} from "../../../shared/services/recuperar-password.service";
import {ReqRecuperarPassword} from "../../../interfaces/recuperar-pass.interface";
import { MatDialog } from '@angular/material/dialog';
import {ModalSolicitudEnviadaComponent} from "../login/modal-solicitud-enviada/modal-solicitud-enviada.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {environment} from "../../../../environments/environment";
import {of} from "rxjs";
import {ReenvioEnlacePasswordService} from "../../../shared/services/reenvio-enlace-password.service";
import {IRespDataEnlace, IRespEnlace} from "../../../shared/interfaces/reenvio-enlace.interface";

@Component({
  selector: 'soph-modal-recuperar-password',
  standalone: true,
  imports: [MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    CommonModule,
    MatProgressSpinnerModule],
  templateUrl: './modal-recuperar-password.component.html',
  styleUrl: './modal-recuperar-password.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalRecuperarPasswordComponent {
  recuperarPass: FormGroup;
  private auth = inject(Auth);
  get email() {
    return this.recuperarPass.get('email');
  }
  public isLoading = false;
  constructor(private fb: FormBuilder,
              private cdr: ChangeDetectorRef,
              private router: Router,
              public dialog: MatDialog,
              private recuperarPasswordService: RecuperarPasswordService){
    const formatoEmail = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    this.recuperarPass = this.fb.group({

      email: ['',[Validators.required,
        Validators.pattern(formatoEmail)],
        [this.emailExistsValidator()]]
    })
    this.auth.tenantId = environment.tenantId;
  }
  ngOnInit(): void {
  }


  onSumbit(){

    if(this.recuperarPass.valid){

    }
  }

  public disabled() {
    return this.recuperarPass.valid;
}

  volverAlogin(){
    this.router.navigate(['/login']); // redireccionamiento login
  }

  getEmaildErrorMessage(): string {
    const email = this.recuperarPass.get('email');
    if (email?.hasError('required')) {
      return 'Por favor ingrese un correo electrónico';
    }
    if(email?.hasError('domainNotExists')){
      return 'El dominio del correo electrónico no es valido';
    }
    if(email?.hasError('emailNotExists')) {
      return 'El correo electrónico no está registrado';
    }
    return email?.hasError('pattern') ? 'Por favor, ingrese un correo válido' : '';
  }


  public recuperarPassword() {
    const email =this.recuperarPass.get('email')?.value;
    const clientId ="4b9b9ab5b734408d915ec31751bbf114";
    this.isLoading = true;
    this.recuperarPasswordService.postRecuperarPassword(clientId, email).subscribe({
      next: (response) => {
        this.isLoading = false;
        this.dialog.open(ModalSolicitudEnviadaComponent);
      },
      error: (err) => {
        console.error('API call failed:', err);
        this.isLoading = false;
      },
    });
  }

  emailExistsValidator(): AsyncValidatorFn {
    return (control: AbstractControl) => {
      const email = control.value;
      if (!email) {
        return of(null);
      }
      return fetchSignInMethodsForEmail(this.auth, email).then(
        (methods) => {
          return methods.length === 0 ? { emailNotExists: true } : null;
        }
      ).catch((error) => {
        console.error('Error al verificar el correo:', error);
        return null;
      });
    };
  }
  public restringirCaracteresEspeciales(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value;
    value = value.replace(/[^a-zA-Z0-9._@-]/g, '');
    this.recuperarPass.controls['email'].setValue(value);
  }

  }



