export interface IRespEvent {
    success: boolean
    data: IDataEvent[]
    error: any
  }

  export interface IDataEvent {
    id:       string;
    title:    string;
    start:    Date;
    end:      string;
    tipo:     TipoEvento;
    color:    string;
    metadata: DataBloqueo | null;
}

export interface DataBloqueo {
    tipoBloqueo: string;
    descripcion: string;
}

export enum TipoEvento {
    Agendamiento = "AGENDAMIENTO",
    Bloqueo = "BLOQUEO",
}
