import {inject, Injectable} from '@angular/core';
import {Auth,
  onIdTokenChanged,} from "@angular/fire/auth";
import { getIdToken, User } from 'firebase/auth';
import {Router} from "@angular/router";
import { confirmPasswordReset } from 'firebase/auth';
import {Observable, from} from "rxjs";
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private auth = inject(Auth);
  constructor(private router: Router) {
    this.listenToTokenChanges();
  }


  getToken(): string | null {
    return localStorage.getItem('authToken');
  }

  listenToTokenChanges() {
    onIdTokenChanged(this.auth, (user: User | null) => {
      if (user) {
        this.refreshToken(user);
      }
    });
  }

   async refreshToken(user: User) {
    try {
      const token = await getIdToken(user, true);
      localStorage.setItem('authToken', token);
    } catch (error) {
      console.error('Error al refrescar el token:', error);
    }
  }

  confirmPasswordReset(oobCode: string,
                       newPassword: string): Observable<void> {
    return from(confirmPasswordReset(this.auth, oobCode, newPassword));
  }

  isAuthenticated(): boolean {
    return this.getToken() !== null;
  }
}
