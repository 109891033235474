import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import {
  IDataExamenesAtencion,
  IDataExamn,
  IDataTypeExamn,
  IGenericResponse,
} from '../interfaces/examenes.interface';

export interface RespUploadFile {
  success: boolean;
  data: UploadedFile;
  error: string | null;
}

export interface UploadedFile {
  destination: string;
  fileName: string;
}

@Injectable({
  providedIn: 'root',
})

export class TabExamenesService {
  private apiUrl = environment.baseURL + 'datos-medicos';
  private filesApiUrl = environment.baseURL + 'files';
  private fichaClinicaApiUrl = environment.baseURL + 'ficha-clinica';

  constructor(private httpClient: HttpClient) {}

  getTypesExams(): Observable<IGenericResponse<IDataTypeExamn[]>> {
    return this.httpClient.get<IGenericResponse<IDataTypeExamn[]>>(
      this.apiUrl + '/tipos-examenes'
    );
  }

  getExamsByType(): Observable<IGenericResponse<IDataExamn[]>> {
    return this.httpClient.get<IGenericResponse<IDataExamn[]>>(
      this.apiUrl + `/examenes`
    );
  }

  uploadFile(file: File, idPaciente: string, idAgendamiento: string): Observable<RespUploadFile> {
    const formData = new FormData();
    formData.append('file', file);
    return this.httpClient.post<RespUploadFile>(this.filesApiUrl + `/upload/${idPaciente}/${idAgendamiento}`, formData);
  }

  deleteFile(url: string): Observable<{ message: string }> {
    return this.httpClient.delete<{ message: string }>(this.filesApiUrl + `/delete-file`, {
      params: { url },
    });
  }

  getExamenesAtencion(idAgendamiento: string): Observable<IGenericResponse<IDataExamenesAtencion>> {
    return this.httpClient.get<IGenericResponse<IDataExamenesAtencion>>(this.fichaClinicaApiUrl + `/examenes/${idAgendamiento}`);
  }

}
