import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Output, signal } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { distinctUntilChanged, Subscription, zip } from 'rxjs';
import { InputFormFieldComponent } from '../../../../../shared/components/input-form-field/input-form-field.component';
import { MultiselectFormfieldComponent } from '../../../../../shared/components/multiselect-formfield/multiselect-formfield.component';
import { SelectFormFieldComponent } from '../../../../../shared/components/select-form-field/select-form-field.component';
import {
  Controles,
  IDataMedicamento,
  IMedControl,
  IMedSelected,
  IReqMedTratamiento,
  MedGuardado,
} from '../../../../../shared/interfaces/tratamiento/medicamentos.interface';
import { IDataViasAdministracion } from '../../../../../shared/interfaces/vias-admin.interface';
import { TabTratamientoService } from '../../../../../shared/services/tab-tratamiento.service';
import { MultiSelectSearchFormFieldComponent } from '../../../../../shared/components/multi-select-search-form-field/multi-select-search-form-field.component';

@Component({
  selector: 'soph-medicamentos',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    InputFormFieldComponent,
    MatButtonModule,
    MatCardModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    SelectFormFieldComponent,
    MultiSelectSearchFormFieldComponent
  ],
  templateUrl: './medicamentos.component.html',
  styleUrl: './medicamentos.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MedicamentosComponent {
  @Output() isFormValid = new EventEmitter<boolean>;

  medicamentosGuardados : MedGuardado[] = [];
  formMedicamentos: FormGroup;
  public medicamentosOptions = signal<Array<IDataMedicamento>>([]);

  idsMedsSelected: string[] = [];

  medsSelected: IMedSelected[] = [];
  viasAdminOptions: IDataViasAdministracion[] = [];
  originalFormValues = signal<{[key: string]: string | string[], medicamentos: string[]}>({
    medicamentos: []
  });

  subs: Subscription = new Subscription;

  get medicamentos(): FormControl {
    return this.formMedicamentos.get(
      'medicamentos'
    ) as FormControl<IDataMedicamento>;
  }
  get medsEnInput(): string[] {
    return this.medicamentos.value
  }

  constructor(
    private fb: FormBuilder,
    private TabTratamientoService: TabTratamientoService
  ) {
    this.formMedicamentos = this.fb.group({
      medicamentos: [''],
    });

    this.medicamentos
      ?.valueChanges.pipe(distinctUntilChanged())
      .subscribe((values) => {

        if( values && !(values === this.idsMedsSelected) ) {
          this.onMedSelected(values)
          this.idsMedsSelected = values;

        }

      });
  }

  ngOnInit(): void {
    this.llamarServicios();
    this.emitirEstadoForm();
  }

  emitirEstadoForm() {
    const formSub = this.formMedicamentos.statusChanges.subscribe( status => {
      const formState = (status === 'VALID');
      this.isFormValid.emit(formState);
    })

    this.subs.add(formSub);
  }

  armarRequestMedicamentos() : IReqMedTratamiento[] {
    // Valores originales
    this.originalFormValues.set(this.formMedicamentos.value)

    const meds: IReqMedTratamiento[] = [];
    if(this.medsEnInput && this.medsEnInput.length > 0) {
      this.medsEnInput.forEach( (idMed) => {
        const med:  IReqMedTratamiento = {
          idMedicamento: idMed,
          dosis: this.formMedicamentos.get(`dosis${idMed}`)?.value,
          duracion: this.formMedicamentos.get(`duracion${idMed}`)?.value,
          horario: this.formMedicamentos.get(`horario${idMed}`)?.value,
          idViaAdministracion: this.formMedicamentos.get(`vias${idMed}`)?.value
        }
        meds.push(med)
      })
      return meds;
    }
    return []
  }

  editarMedicamentos() {
    if(this.medicamentosGuardados.length === 0) return;

    const idsMeds: string[] = this.medicamentosGuardados.map(med => med.idMedicamento)
    // Crear controles
    this.onMedSelected(idsMeds);
    // Setear Valores originales en inputs
    this.formMedicamentos.setValue(this.originalFormValues());
    // Vaciar medicamentos guardados
    this.medicamentosGuardados = [];

  }

  disableSelectorMedicamentos() {
    this.medicamentos.disable()
  }
  enableSelectorMedicamentos() {
    this.medicamentos.enable()
  }

  isOriginalValueEdited(): boolean {
    return !(this.formMedicamentos.value === this.originalFormValues())
  }

  showMedicamentosGuardados(data: IReqMedTratamiento[]) {
    if(data && data.length > 0) {
      data.forEach( med => {
        const medicamento = this.medicamentosOptions().find(o => o.idMedicamento === med.idMedicamento)
        if(medicamento){
          const medGuardado: MedGuardado = {
            dosis: med.dosis,
            duracion: med.duracion,
            nombreMedicamento: medicamento.nombre,
            horario: med.horario,
            idMedicamento: med.idMedicamento
          }
          this.medicamentosGuardados.push(medGuardado)
        }
      })
    }
    this.resetForm();

  }

  setOriginalValues(data: IReqMedTratamiento[]) {
    console.log(data)
    const originalValues = data.reduce((acc, med) => {
      acc['dosis'+med.idMedicamento] = med.dosis;
      acc['duracion'+med.idMedicamento] = med.duracion;
      acc['horario'+med.idMedicamento] = med.horario;
      acc['vias'+med.idMedicamento] = med.idViaAdministracion;
      if(acc['medicamentos']) {
        acc['medicamentos'].push(med.idMedicamento)
      } else {
        acc['medicamentos'] = [med.idMedicamento]
      }
      return acc;
    }, {} as {[key: string]: string | string[], medicamentos: string[]})

     this.originalFormValues.set(originalValues)
     console.log(this.originalFormValues())
  }

  resetForm() {
    this.medsSelected = [];
    this.idsMedsSelected = [];
    const nombresControles = Object.keys(this.formMedicamentos.controls);
    nombresControles.forEach( nombreControl =>{
      if( nombreControl != 'medicamentos') {
        this.formMedicamentos.removeControl(nombreControl);
      }
    })
    this.formMedicamentos.reset();
  }

  llamarServicios() {
    const servicesSub = zip(
      this.TabTratamientoService.getViasAdministracion(),
      this.TabTratamientoService.getMedicamentos()
    ).subscribe({
      next: ([viasAdmin, medicamentos]) => {
        this.viasAdminOptions = viasAdmin.data;
        this.medicamentosOptions.set(medicamentos.data);
      },
    });

    this.subs.add(servicesSub);
  }

  // Crear controles para dosis, duracion y horario por cada medicamento seleccionado
  createControlsMeds(idMedicamento: string): Controles {
    const medControls: Controles = {
      generales: [],
      viaAdmin: {} as IMedControl,
    };
    //Por cada medicamento, crear un 3 controles
    const dosisName = `dosis${idMedicamento}`;
    const duracionName = `duracion${idMedicamento}`;
    const horarioName = `horario${idMedicamento}`;
    const viasName = `vias${idMedicamento}`;
    // añadir a array
    medControls.generales.push({
      nombreControl: dosisName,
      medControl: new FormControl('', [Validators.required]),
      labelControl: 'Dosis',
    });
    medControls.generales.push({
      nombreControl: duracionName,
      medControl: new FormControl('', [Validators.required]),
      labelControl: 'Duración',
    });
    medControls.generales.push({
      nombreControl: horarioName,
      medControl: new FormControl('', [Validators.required]),
      labelControl: 'Horario',
    });
    const viaAdmin = {
      nombreControl: viasName,
      medControl: new FormControl('', [Validators.required]),
      labelControl: 'Vía de administración',
    };
    medControls.viaAdmin = viaAdmin;

    return medControls;
  }
  onMedSelected(idsMedsEmitidos: string[]) {
    const medsEliminados = this.idsMedsSelected.filter(id => !idsMedsEmitidos.includes(id))
    const medsAgregados = idsMedsEmitidos.filter(id => !this.idsMedsSelected.includes(id))
    this.idsMedsSelected = idsMedsEmitidos
    medsEliminados.forEach(idMed => this.removeMedicamento(idMed))
    medsAgregados.forEach(idMed => this.addMedicamento(idMed))
  }

  addMedicamento(idMedicamento: string) {
    // Preparar data
    const medOption = this.medicamentosOptions().find((med) => med.idMedicamento === idMedicamento)
    const nombreMedicamento = medOption ? medOption.nombre : '';
    const controles = this.createControlsMeds(idMedicamento);

    // añadir controles al formulario
    this.addMedControls(controles);

    // Agregar medicamento a array de medicamentos seleccionados
    const medicamento: IMedSelected = {
      nombreMedicamento,
      idMedicamento,
      controles,
    };
    this.medsSelected.push(medicamento);
  }
  removeMedicamento(idMedicamento: string) {
    // Al deseleccionar un medicamento
    const medToDelet = this.medsSelected.find(
      (med) => med.idMedicamento === idMedicamento
    );
    if (medToDelet) {
      // eliminar controles
      this.removeMedControls(medToDelet.controles);

      // eliminar medicamento de la seleccion
      const medsFilter = this.medsSelected.filter(
        (med) => med.idMedicamento !== idMedicamento
      );
      this.medsSelected = medsFilter;
    }
  }
  unselectMed(idMedicamento: string) {
    const meds = this.medsEnInput;
    const newMeds: string[] = [];
    meds.forEach( (id) => {
      if(idMedicamento != id) {
        newMeds.push(id);
      }
    })
    this.medicamentos.setValue(newMeds)
  }
  addMedControls(controles: Controles) {
    let controlsList: IMedControl[] = [
      ...controles.generales,
      controles.viaAdmin,
    ];
    controlsList.forEach(({ nombreControl, medControl }) => {
      this.addNewControls(nombreControl, this.formMedicamentos, medControl);
    });
  }
  removeMedControls(controles: Controles) {
    let controlsList: IMedControl[] = [
      ...controles.generales,
      controles.viaAdmin,
    ];
    controlsList.forEach(({ nombreControl }) => {
      this.removeControls(nombreControl, this.formMedicamentos);
    });
  }

  addNewControls(nombre: string, form: FormGroup, control: FormControl) {
    form.addControl(nombre, control);
  }
  removeControls(nombre: string, form: FormGroup) {
    form.removeControl(nombre);
  }
}
