import { Injectable, inject } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable, map, forkJoin } from "rxjs";
import { ModalFileViewerComponent, FileType, ModalFileViewerData } from "../../../../shared/components/modal-file-viewer/modal-file-viewer.component";
import { ModalSpinnerComponent } from "../../../../shared/components/modal-spinner/modal-spinner.component";
import { TipoReporteResumenClinico } from "../../../../shared/enums";
import { FichaClinicaStore } from "../../../../shared/stores/ficha-clinica/ficha-clinica.store";
import { ReportesService } from "../reportes.service";

@Injectable({
  providedIn: 'root',
})
export class ResumenClinicoService {
  private readonly _reportesService = inject(ReportesService);
  private readonly _fichaClinicaStore = inject(FichaClinicaStore);
  private readonly _dialog = inject(MatDialog);

  public openResumenClinicoModal(): void {
    const reportes = this.obtenerReportesResumenClinico();
    this.procesarReportes(reportes);
  }

  public openReceta(idAgendamiento: string): void {
    const recetas = this.obtenerRecetaMedica(idAgendamiento);
    this.procesarReportes(recetas);
  }

  private procesarReportes(
    reportes: Array<{
      nombre: TipoReporteResumenClinico;
      blob: Observable<Blob>;
      order: number;
    }>
  ): void {
    const _spinner = this._dialog.open(ModalSpinnerComponent, {
      disableClose: true,
    });

    const pdfBlobs = reportes.map((data) =>
      data.blob.pipe(map((blob) => ({ blob, name: data.nombre, order: data.order })))
    );

    forkJoin(pdfBlobs).subscribe({
      next: (pdfBlobs) => {
        _spinner.close();

        const orderedPdfBlobs = pdfBlobs.sort((a, b) => a.order - b.order);

        const modal = this._dialog.open(ModalFileViewerComponent, {
          panelClass: 'file-viewer-dialog',
          disableClose: true,
          autoFocus: false,
          data: {
            files: orderedPdfBlobs.map(({ blob, name }) => ({ blob, name })),
            type: FileType.PDF,
          } as ModalFileViewerData,
        });

        modal.componentInstance.sendAction$.subscribe((value) => {
          if (value) {
            this._reportesService
              .enviarResumenClinicoPorNombre(
                value as TipoReporteResumenClinico,
                this._fichaClinicaStore.values.idAgendamiento!
              )
              .subscribe();
          }
        });
      },
      error: (error) => {
        _spinner.close();
        console.error('Error al obtener los PDFs:', error);
      },
    });
  }

  private obtenerReportesResumenClinico(): Array<{
    nombre: TipoReporteResumenClinico;
    blob: Observable<Blob>;
    order: number;
  }> {
    const idAgendamiento = this._fichaClinicaStore.values.idAgendamiento!;
    return this.configurarReportes([
      { nombre: TipoReporteResumenClinico.RecetaMedica, order: 2 },
      { nombre: TipoReporteResumenClinico.InformeMedico, order: 1 },
      { nombre: TipoReporteResumenClinico.Terapias, order: 3 },
      { nombre: TipoReporteResumenClinico.Examenes, order: 4 },
    ], idAgendamiento);
  }

  private obtenerRecetaMedica(idAgendamiento: string): Array<{
    nombre: TipoReporteResumenClinico;
    blob: Observable<Blob>;
    order: number;
  }> {
    return this.configurarReportes([
      { nombre: TipoReporteResumenClinico.RecetaMedica, order: 1 },
    ], idAgendamiento);
  }

  private configurarReportes(
    configuraciones: Array<{ nombre: TipoReporteResumenClinico; order: number }>,
    idAgendamiento: string
  ): Array<{
    nombre: TipoReporteResumenClinico;
    blob: Observable<Blob>;
    order: number;
  }> {
    return configuraciones.map(({ nombre, order }) => ({
      nombre,
      blob: this._reportesService.obtenerResumenClinicoPorNombre(nombre, idAgendamiento),
      order,
    }));
  }
}
