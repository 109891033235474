import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  signal,
  WritableSignal,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { isEqual } from 'lodash';
import moment, { Moment } from 'moment';
import { distinctUntilChanged, forkJoin, Observable } from 'rxjs';
import { DatePickerFormFieldComponent } from '../../../../shared/components/date-picker-form-field/date-picker-form-field.component';
import { InputAutocompleteFormfieldComponent } from '../../../../shared/components/input-autocomplete-formfield/input-autocomplete-formfield.component';
import { InputFormFieldComponent } from '../../../../shared/components/input-form-field/input-form-field.component';
import { InputTelephoneFichaComponent } from '../../../../shared/components/input-telephone-ficha/input-telephone-ficha.component';
import { ModalConfirmCambioDatosComponent } from '../../../../shared/components/modal-confirm-cambio-datos/modal-confirm-cambio-datos.component';
import {
  ModalConfirmComponent,
  ModalConfirmProps,
  ModalConfirmType,
} from '../../../../shared/components/modal-confirm/modal-confirm.component';
import { RadioButtonFormFieldComponent } from '../../../../shared/components/radio-button-form-field/radio-button-form-field.component';
import { SelectFormFieldComponent } from '../../../../shared/components/select-form-field/select-form-field.component';
import {
  SnackInfoComponent,
  SnackInfoType,
} from '../../../../shared/components/snack-info/snack-info.component';
import { fichaClinicaFinalizada } from '../../../../shared/helpers/ficha-clinica.helper';
import { IDataDireccion } from '../../../../shared/interfaces/buscar-direccion.interface';
import { IDataCentrosMedico } from '../../../../shared/interfaces/centros-medicos.interface';
import { IdataEstadoCivil } from '../../../../shared/interfaces/estado-civil.interface';
import { IDataEnitas } from '../../../../shared/interfaces/etnias.interface';
import { IReqDataPaciente } from '../../../../shared/interfaces/exite-paciente.interface';
import { IdataGenero } from '../../../../shared/interfaces/genero.interface';
import { IdataGrupoSanguineo } from '../../../../shared/interfaces/grupo-sanguineo.interface';
import { IdataOcupaciones } from '../../../../shared/interfaces/ocupacion.interface';
import { IdataPaises } from '../../../../shared/interfaces/paises.interface';
import { IDataParentezco } from '../../../../shared/interfaces/parentezco.interface';
import { IdataRegiones } from '../../../../shared/interfaces/regiones.interface';
import { IDataReligion } from '../../../../shared/interfaces/religion.interface';
import { IDataSeguro } from '../../../../shared/interfaces/seguros.interface';
import { IDataDocumento } from '../../../../shared/interfaces/tipo-documento.interface';
import { BuscardorDireccionService } from '../../../../shared/services/buscardor-direccion.service';
import { ExisteAcompananteService } from '../../../../shared/services/existe-acompanante.service';
import { GuardadoDatosRegistroService } from '../../../../shared/services/guardado-datos-registro.service';
import { ObtenerInstitucionesService } from '../../../../shared/services/obtener-instituciones.service';
import { ObtenerPrevisionService } from '../../../../shared/services/obtener-prevision.service';
import { ObtenerTipoDocumentoService } from '../../../../shared/services/obtener-tipo-documento.service';
import { PacienteService } from '../../../../shared/services/paciente.service';
import { ServiceFormIdentificacionService } from '../../../../shared/services/service-form-identificacion.service';
import { ShareDataService } from '../../../../shared/services/share-data.service';
import { UtilServiceService } from '../../../../shared/services/util-service.service';
import { FichaClinicaStore } from '../../../../shared/stores/ficha-clinica/ficha-clinica.store';
import { ModalErrorGeneralComponent } from '../../../common/modal-error-general/modal-error-general.component';
import { AgendamientosService } from '../../../services/agendamientos.service';
import { IDataRespAcompanante } from './../../../../shared/interfaces/existe-acompanante.interface';
import {
  IDataExtPaciente,
  IDataFormularioAcompanante,
  IDataFormularioPaciente,
  IDataReqAcompanante,
} from './../../../../shared/interfaces/exite-paciente.interface';

interface NumDomAcomData {
  label: string;
  errorMessages: {
    required: string;
    minlength: string;
  },
  inputValidate: (event: any) => void
}

@Component({
  selector: 'soph-registro',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatDatepickerModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDividerModule,
    MatRadioModule,
    MatGridListModule,
    InputFormFieldComponent,
    SelectFormFieldComponent,
    DatePickerFormFieldComponent,
    InputTelephoneFichaComponent,
    RadioButtonFormFieldComponent,
    MatAutocompleteModule,
    InputAutocompleteFormfieldComponent,
  ],
  providers: [provideNativeDateAdapter()],
  templateUrl: './registro.component.html',
  styleUrl: './registro.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegistroComponent {
  readonly path = 'indentificacion';
  genero = new FormControl('');
  formRegistroPaciente: FormGroup;
  formRegistroAcompanante: FormGroup;
  showSeccionAcompanante = false;

  acompanante: FormControl = new FormControl(['Si']);

  formPaciente = signal<any>(null);
  formAcompanante = signal<any>(null);
  savedDataPaciente: WritableSignal<IDataFormularioPaciente | null> = signal<IDataFormularioPaciente | null>(null);
  savedDataAcompanante: WritableSignal<IDataFormularioAcompanante | null> = signal<IDataFormularioAcompanante | null>({
    tipoDocAcom: '',
    numDocAcom: '',
    nombreAcom: '',
    apellidoAcom: '',
    fechaNacimientoAcom: '',
    parentezcoAcom: '',
    telefonoAcom: '',
  });
  isDataSaved = false;

  /*GET CAMPOS FORM*/
  get idTipoDocumento(): FormControl {
    return this.formRegistroPaciente.get('idTipoDocumento') as FormControl;
  }
  get numeroDocumento(): FormControl {
    return this.formRegistroPaciente.get('numeroDocumento') as FormControl;
  }
  get historiaClinica(): FormControl {
    return this.formRegistroPaciente.get('historiaClinica') as FormControl;
  }
  get tipoSeguro(): FormControl {
    return this.formRegistroPaciente.get('tipoSeguro') as FormControl;
  }
  get nombre(): FormControl {
    return this.formRegistroPaciente.get('nombre') as FormControl;
  }
  get apellido(): FormControl {
    return this.formRegistroPaciente.get('apellido') as FormControl;
  }
  get fechaNacimiento(): FormControl {
    return this.formRegistroPaciente.get('fechaNacimiento') as FormControl;
  }
  get edad(): FormControl {
    return this.formRegistroPaciente.get('edad') as FormControl;
  }
  get idSexo(): FormControl {
    return this.formRegistroPaciente.get('idSexo') as FormControl;
  }
  get idEstadoCivil(): FormControl {
    return this.formRegistroPaciente.get('idEstadoCivil') as FormControl;
  }
  get paisNacimineto(): FormControl {
    return this.formRegistroPaciente.get('paisNacimineto') as FormControl;
  }
  get ciudadNacimiento(): FormControl {
    return this.formRegistroPaciente.get('ciudadNacimiento') as FormControl;
  }
  get idReligion(): FormControl {
    return this.formRegistroPaciente.get('idReligion') as FormControl;
  }
  get etnia(): FormControl {
    return this.formRegistroPaciente.get('etnia') as FormControl;
  }
  get direccionCompleta(): FormControl {
    return this.formRegistroPaciente.get('direccionCompleta') as FormControl;
  }
  get numeroCasa(): FormControl {
    return this.formRegistroPaciente.get('numeroCasa') as FormControl;
  }
  get idGradoInstruccion(): FormControl {
    return this.formRegistroPaciente.get('idGradoInstruccion') as FormControl;
  }
  get ocupacion(): FormControl {
    return this.formRegistroPaciente.get('ocupacion') as FormControl;
  }
  get numeroControl(): FormControl {
    return this.formRegistroPaciente.get('numero') as FormControl;
  }
  get email(): FormControl {
    return this.formRegistroPaciente.get('email') as FormControl;
  }
  get idGrupoSanguineo(): FormControl {
    return this.formRegistroPaciente.get('idGrupoSanguineo') as FormControl;
  }
  get peso(): FormControl {
    return this.formRegistroPaciente.get('peso') as FormControl;
  }
  get altura(): FormControl {
    return this.formRegistroPaciente.get('altura') as FormControl;
  }
  get tipoDocAcom(): FormControl {
    return this.formRegistroAcompanante.get('tipoDocAcom') as FormControl;
  }
  get numDocAcom(): FormControl {
    return this.formRegistroAcompanante.get('numDocAcom') as FormControl;
  }
  get nombreAcom(): FormControl {
    return this.formRegistroAcompanante.get('nombreAcom') as FormControl;
  }
  get apellidoAcom(): FormControl {
    return this.formRegistroAcompanante.get('apellidoAcom') as FormControl;
  }
  get edadAcom(): FormControl {
    return this.formRegistroAcompanante.get('edadAcom') as FormControl;
  }
  get telefonoAcom(): FormControl {
    return this.formRegistroAcompanante.get('telefonoAcom') as FormControl;
  }
  get fechaNacimientoAcom(): FormControl {
    return this.formRegistroAcompanante.get(
      'fechaNacimientoAcom'
    ) as FormControl;
  }
  get parentezcoAcom(): FormControl {
    return this.formRegistroAcompanante.get('parentezcoAcom') as FormControl;
  }

  esEditable: boolean = false;

  /*DATA FORM*/
  pasaporteId = signal<string>('');
  dniId = signal<string>('');
  otrosId = signal<string>('');
  paisesOptions = signal<IdataPaises[]>([]);
  regionesOptions = signal<IdataRegiones[]>([]);
  generoOptions = signal<IdataGenero[]>([]);
  estadoCivilOptions = signal<IdataEstadoCivil[]>([]);
  gradoInstruccionOptions = signal<IdataOcupaciones[]>([]);
  grupoSanguineoOptions = signal<IdataGrupoSanguineo[]>([]);
  religionOptions = signal<IDataReligion[]>([]);
  etniasOptions = signal<IDataEnitas[]>([]);
  tipoPrevisionOptions = signal<IDataSeguro[]>([]);
  parentezcoOptions = signal<IDataParentezco[]>([]);
  listPrevisiones = signal<any>(null);

  dataExisteAcompanante: IDataRespAcompanante | null = null;
  tipoDocumentoOptions = [
    { idTipoDocumento: '89afaff0-6e5b-4d15-ac65-47900d6d35ac', nombre: 'DNI' },
    {
      idTipoDocumento: '55823300-6b75-4245-9d18-384a3051e30d',
      nombre: 'PASAPORTE',
    },
    { idTipoDocumento: 'another-id', nombre: 'Otro Documento' },
  ];

  tipoDocuementoAcom: IDataDocumento[] = [];
  institucionesOptions: IDataCentrosMedico[] = [];
  dataExistePaciente: IDataFormularioPaciente = {} as IDataFormularioPaciente;
  infoNumDocAcom: WritableSignal<NumDomAcomData> = signal<NumDomAcomData>({
    label: 'N° Documento',
    errorMessages: {
      required: 'Debes ingresar el número de documento',
      minlength: 'El documento ingresado no es válido'
    },
    inputValidate: this.manejoDataDni.bind(this)
  })

  listDirecciones: IDataDireccion[] = [];
  filterDireccion!: Observable<string[]>;
  filteredOptionsEmptyDireccion: boolean = false;
  addresDireccionSeleccionada: any;
  idDirecSeleccionada = signal<string | null>(null);
  labelNumeroDocumento: string = 'Número documento';
  options: string[] = ['Si', 'No'];
  optionsExisteAcom: string[] = ['Si', 'No'];
  requestData: any;
  numeroDocPaciente: any; // Label dinámico
  telephonePrefix: string = '';
  guardadoDatosResp: any;
  idDireccionSeleccionada: any;
  idPacient: any;

  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private dialog: MatDialog,

    private obtenerPrevisionService: ObtenerPrevisionService,
    private obtenerTipoDocumentoService: ObtenerTipoDocumentoService,
    private obtenerInstitucionesService: ObtenerInstitucionesService,
    private datosPersonalesService: ServiceFormIdentificacionService,
    private snackBar: MatSnackBar,
    private ShareDataService: ShareDataService,
    private buscarDireccionService: BuscardorDireccionService,
    private ExisteAcompananteService: ExisteAcompananteService,
    private utilServiceService: UtilServiceService,
    private guardadoDatosRegistroService: GuardadoDatosRegistroService,

    private readonly _fichaClinicaStore: FichaClinicaStore,
    private readonly _pacienteService: PacienteService,
    private readonly _agendamientosService: AgendamientosService
  ) {
    const formatoEmail = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    this.formRegistroPaciente = this.fb.group({
      idTipoDocumento: ['', Validators.required],
      numeroDocumento: ['', Validators.required],
      historiaClinica: ['', Validators.required],
      tipoSeguro: ['', Validators.required],
      nombre: ['', Validators.required],
      apellido: ['', Validators.required],
      fechaNacimiento: ['', Validators.required],
      edad: [''],
      idSexo: ['', Validators.required],
      idEstadoCivil: ['', Validators.required],
      paisNacimineto: ['', Validators.required],
      ciudadNacimiento: ['', Validators.required],
      idReligion: [''],
      etnia: [''],
      direccionCompleta: ['', Validators.required],
      numeroCasa: [''],
      idGradoInstruccion: [''],
      ocupacion: ['', Validators.required],
      numero: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/)]),
      email: ['', [Validators.required, Validators.pattern(formatoEmail)]],
      idGrupoSanguineo: [''],
      peso: [''],
      altura: [''],
    });

    this.formRegistroAcompanante = this.fb.group({
      tipoDocAcom: [''],
      numDocAcom: [''],
      nombreAcom: [''],
      apellidoAcom: [''],
      fechaNacimientoAcom: [''],
      edadAcom: [''],
      parentezcoAcom: [''],
      telefonoAcom: [''],
    });

    this.acompanante.setValue('No')

    this._fichaClinicaStore.state$.pipe(distinctUntilChanged()).subscribe((values) => {
      this.toggleFormState(fichaClinicaFinalizada(values.estado || ''));
    });

    this.formRegistroPaciente.valueChanges.subscribe((values) => {
      this.formPaciente.set(values);
    })

    this.formRegistroAcompanante.valueChanges.subscribe((values) => {
      this.formAcompanante.set(values);
    })
  }

  ngOnInit(): void {
    this.cargaDeServicios();
  }

  private toggleFormState(shouldDisable: boolean) {
    if (shouldDisable) {
      this.formRegistroPaciente.disable();
      this.formRegistroAcompanante.disable();
    }
    this.formRegistroPaciente.get('idTipoDocumento')?.disable();
    this.formRegistroPaciente.get('numeroDocumento')?.disable();
    this.formRegistroPaciente.get('historiaClinica')?.disable();
    this.formRegistroPaciente.get('edad')?.disable();
    this.formRegistroAcompanante.get('edadAcom')?.disable();
  }


  dataPrecargada(dataPaciente: any) {
    if (dataPaciente) {
      const numeroDocumento = dataPaciente.numeroDocumento;
      const idTipoDocumento = dataPaciente.idTipoDocumento;
      this.existePacienteService(numeroDocumento, idTipoDocumento);
    }
    return;
  }

  actualizarLabelNumeroDocumento(tipoDocSeleccionado: any): void {
    const idDocDni = this.dniId;
    const idDocPasaporte = this.pasaporteId;
    if (tipoDocSeleccionado === idDocDni) {
      const nombreDocumento = 'documento';
      this.labelNumeroDocumento = `Número de ${nombreDocumento}`;
    } else if (tipoDocSeleccionado === idDocPasaporte) {
      const nombrePasaporte = 'pasaporte';
      this.labelNumeroDocumento = `Número de ${nombrePasaporte}`;
    } else {
      this.labelNumeroDocumento = 'Número de documento';
    }
  }

  /*SERVICES*/
  private cargaDeServicios(): void {
    forkJoin({
      paises: this.datosPersonalesService.getPaises(),
      regiones: this.datosPersonalesService.getRegiones(),
      genero: this.datosPersonalesService.getGenero(),
      estadoCivil: this.datosPersonalesService.getEstadoCivil(),
      instrucciones: this.datosPersonalesService.geInstrucciones(),
      grupoSanguineo: this.datosPersonalesService.getGrupoSanguineo(),
      etnias: this.datosPersonalesService.getEtnias(),
      religion: this.datosPersonalesService.getReligion(),
      previsiones: this.obtenerPrevisionService.getPrevision(),
      tipoDocumento: this.obtenerTipoDocumentoService.getTipoDocumento(),
      parentezco: this.ExisteAcompananteService.getParentezcoAcompanante(),
    }).subscribe({
      next: (result) => {
        this.paisesOptions.set(result.paises.data);
        this.regionesOptions.set(result.regiones.data);
        this.generoOptions.set(result.genero.data);
        this.estadoCivilOptions.set(result.estadoCivil.data);
        this.gradoInstruccionOptions.set(result.instrucciones.data);
        this.grupoSanguineoOptions.set(result.grupoSanguineo.data);
        this.etniasOptions.set(result.etnias.data);
        this.religionOptions.set(result.religion.data);
        this.tipoPrevisionOptions.set(result.previsiones.data);
        this.listPrevisiones.set(result.previsiones.data);

        this.procesarTipoDocumento(result.tipoDocumento.data);

        this.parentezcoOptions.set(result.parentezco.data);

        // Traer datos del paciente
        if (this._fichaClinicaStore.values.idPaciente) {
          this.getPaciente(this._fichaClinicaStore.values.idPaciente);
        }

        if (this._fichaClinicaStore.values.idAgendamiento) {
          this.getAgendamiento(this._fichaClinicaStore.values.idAgendamiento);
        }
      },
      error: (err) => console.error('Error al cargar los servicios:', err),
    });
  }

  private procesarTipoDocumento(data: IDataDocumento[]): void {
    this.tipoDocumentoOptions = data;
    this.pasaporteId.set(data.find((doc) => doc.nombre === 'Pasaporte')?.idTipoDocumento || '');
    this.dniId.set(data.find((doc) => doc.nombre === 'DNI')?.idTipoDocumento || '');
    this.otrosId.set(data.find((doc) => doc.nombre === 'Otro')?.idTipoDocumento || '');
  }

  public getAgendamiento(idAgendamiento: string) {
    this._agendamientosService.getAgendamientoById(idAgendamiento).subscribe({
      next: (response) => {
        if(!response.data) return;

        const {
          paciente: { numeroDocumento },
          acompanante,
        } = response.data;

        if(!numeroDocumento) return;
        this.getPaciente(numeroDocumento);

        if(acompanante) {
          this.acompanante.setValue('Si');
          this.showSeccionAcompanante = true;
          this.getAcompanante(acompanante.numeroDocumento);
        }

      }
    })

  }


  public getAcompanante(numeroDocumento: string) {
    // this.getNumDocAcom();

    if (!numeroDocumento) return;

    this.ExisteAcompananteService.getExisteAcompanante(
      numeroDocumento
    ).subscribe({
      next: (resp) => {
        if (resp.data) {
          this.dataExisteAcompanante = resp.data;

          this.setDataAcompanante(resp.data);
        }
      },
    });
  }

  public getPaciente(numeroDocumento: string) {
    this._agendamientosService.getExistePaciente(numeroDocumento).subscribe({
      next: (resp) => {
        const existePaciente = resp.data;
        if(!existePaciente) return;

        this.ShareDataService.setExistePaciente(resp.data);
        this.cdr.detectChanges();

        this.idPacient = existePaciente.idPaciente;

        const data: IDataFormularioPaciente = {
          idTipoDocumento: existePaciente.tipoDocumento.idTipoDocumento || '',
          numeroDocumento: existePaciente.numeroDocumento || '',
          historiaClinica: existePaciente.numeroDocumento || '',
          nombre: existePaciente.nombres || '',
          apellido: existePaciente.apellidos || '',
          email: existePaciente.correoElectronico || '',
          numero: existePaciente.telefono || '',
          tipoSeguro: existePaciente.prevision.idPrevision || '',
          fechaNacimiento: existePaciente.fechaNacimiento ?? ' ',
          edad: existePaciente.edad ?? '',
          idSexo: existePaciente.sexo?.idSexo ?? '',
          idEstadoCivil: existePaciente.estadoCivil?.idEstadoCivil ?? '',
          idReligion: existePaciente.religion?.idReligion ?? ' ',
          etnia: existePaciente.etnias?.idEtnia ?? '',
          idGradoInstruccion: existePaciente.gradoInstruccion?.idGradoInstruccion ?? '',
          paisNacimineto: existePaciente.paisNacimiento?.idPais ?? '',
          ciudadNacimiento: existePaciente.regionNacimiento?.idRegion ?? '',
          idGrupoSanguineo: existePaciente.grupoSanguineo?.idGrupoSanguineo ?? '',
          ocupacion: existePaciente.ocupacion ?? '',
          direccionCompleta: existePaciente.direccion?.direccionCompleta ?? '',
          peso: existePaciente.peso || '',
          altura: existePaciente.altura || '',
          numeroCasa: existePaciente.direccion?.numeroCasa || '',
        };

        this.dataExistePaciente = { ...data };
        this.formRegistroPaciente.patchValue(data);

        this.formRegistroPaciente.get('numeroDocumento')?.disable();
        this.formRegistroPaciente.get('historiaClinica')?.disable();
        this.formRegistroPaciente.get('idTipoDocumento')?.disable();

        this.savedDataPaciente.set(this.formRegistroPaciente.value);
      },
    });
  }

  setDataAcompanante(data: IDataRespAcompanante) {
    this.tipoDocAcom.setValue(data.idTipoDocumento);
    // this.onDocAcomSelected(data.idTipoDocumento);
    // this.onDateChangeAcom(data.fechaNacimiento);
    this.addRequiredIdAcom()

    this.numDocAcom.setValue(data.numeroDocumento);
    this.nombreAcom.setValue(data.nombre);
    this.apellidoAcom.setValue(data.apellidos);
    this.fechaNacimientoAcom.setValue(data.fechaNacimiento);
    this.parentezcoAcom.setValue(data.idParentesco);
    this.telefonoAcom.setValue(data.telefono);

    this.savedDataAcompanante.set(this.formRegistroAcompanante.value);
    this.cdr.detectChanges();
  }

  public existePacienteService(
    numeroDocumento: string,
    idTipoDocumento: string
  ) {
    if (!numeroDocumento || !idTipoDocumento) {
      return;
    }

    this._agendamientosService.getExistePaciente(numeroDocumento).subscribe({
      next: (resp) => {
        const existePaciente = resp.data;
        if(existePaciente){
          this.ShareDataService.setExistePaciente(resp.data);
          this.cdr.detectChanges();
        }
        this.idPacient = resp.data.idPaciente;
        if (
          !existePaciente ||
          !existePaciente.tipoDocumento ||
          !existePaciente.numeroDocumento
        ) {
          return;
        }

        const numeroDocumentoService = existePaciente?.numeroDocumento;
        const idTipoDocumentoService =
          existePaciente?.tipoDocumento.idTipoDocumento;

        const data: IDataFormularioPaciente = {
          idTipoDocumento: idTipoDocumento,
          numeroDocumento: existePaciente.numeroDocumento || numeroDocumento,
          historiaClinica: existePaciente.numeroDocumento || '',
          nombre: existePaciente.nombres || '',
          apellido: existePaciente.apellidos || '',
          email: existePaciente.correoElectronico || '',
          numero: existePaciente.telefono || '',
          tipoSeguro: existePaciente.prevision.idPrevision || '',
          fechaNacimiento: existePaciente.fechaNacimiento ?? ' ',
          edad: existePaciente.edad ?? '',
          idSexo: existePaciente.sexo?.idSexo ?? '',
          idEstadoCivil: existePaciente.estadoCivil?.idEstadoCivil ?? '',
          idReligion: existePaciente.religion?.idReligion ?? ' ',
          etnia: existePaciente.etnias?.idEtnia ?? '',
          idGradoInstruccion:
            existePaciente.gradoInstruccion?.idGradoInstruccion ?? '',
          paisNacimineto: existePaciente.paisNacimiento?.idPais ?? '',
          ciudadNacimiento: existePaciente.regionNacimiento?.idRegion ?? '',
          idGrupoSanguineo:
            existePaciente.grupoSanguineo?.idGrupoSanguineo ?? '',
          ocupacion: existePaciente.ocupacion ?? '',
          direccionCompleta: existePaciente.direccion?.direccionCompleta ?? '',
          peso: existePaciente.peso || '',
          altura: existePaciente.altura || '',
          numeroCasa: existePaciente.direccion?.numeroCasa || ''
        };

        if (
          numeroDocumento === numeroDocumentoService &&
          idTipoDocumento === idTipoDocumentoService
        ) {
          this.dataExistePaciente = { ...data };
          this.formRegistroPaciente.patchValue(data);

          this.formRegistroPaciente.get('numeroDocumento')?.disable();
          this.formRegistroPaciente.get('historiaClinica')?.disable();
          this.formRegistroPaciente.get('idTipoDocumento')?.disable();
        }
      },
    });
  }

  /*MÉTODOS PARA RESCATAR DATA DE CAMPOS FORM*/
  public onSelectDireccion(selectedAddress: any): void {
    //se busca la direccion seleccionada en el la lista de direcciones
    const direccionSeleccionada = this.listDirecciones.find(
      (direccion) => direccion.address === selectedAddress
    );
    if (direccionSeleccionada != undefined) {
      const nombreDireccion = direccionSeleccionada?.address;
      //id de la seleccion de direccion
      this.idDireccionSeleccionada = direccionSeleccionada?.id;
    }
    if (direccionSeleccionada) {
      this.formRegistroPaciente.patchValue({
        direccionCompleta: direccionSeleccionada?.address,
      });
    }
  }

  onAcomSelectionChange(selectedValue: string) {
    this.formRegistroAcompanante.reset();

    if (selectedValue === 'Si') {
      this.showSeccionAcompanante = true;
      this.addAcompananteValidators();
    } else {
      this.showSeccionAcompanante = false;
      this.removeAcompananteValidators();
    }
  }

  addAcompananteValidators() {
    Object.keys(this.formRegistroAcompanante.controls).forEach(
      (nombreControl) => {
        const control = this.formRegistroAcompanante.get(nombreControl);
        control?.setValidators([Validators.required]);
        control?.updateValueAndValidity();
      }
    );
  }

  removeAcompananteValidators() {
    Object.keys(this.formRegistroAcompanante.controls).forEach(
      (nombreControl) => {
        this.formRegistroAcompanante.get(nombreControl)?.clearValidators();
        this.formRegistroAcompanante
          .get(nombreControl)
          ?.updateValueAndValidity();
      }
    );
  }

  /*MÉTODOS PARA CALCULAR EDAD SEGUN SELECCION DATEPICKER*/
  onDateChange(event: any) {
    if (!event) return;

    const edad = Math.floor(this.calcularEdad(event));
    this.edad.setValue(edad);
    const esMayorEdad = edad >= 18;

    if (!esMayorEdad) {
      this.acompanante.setValue('Si');
      this.onAcomSelectionChange('Si');
      this.acompanante.disable();
    } else {
      this.acompanante.enable();
    }
  }

  onDateChangeAcom(event: any) {
    const edad = Math.floor(this.calcularEdad(event));
    this.edadAcom.setValue(edad);
  }

  private calcularEdad(fecha: Date | string): number {
    let fechaMoment: Moment;
    if (fecha instanceof Date && fecha) {
      fechaMoment = moment(fecha);
    } else if (typeof fecha === 'string' && fecha) {
      fechaMoment = moment(fecha, 'YYYY-MM-DD');
    } else {
      return 0;
    }
    const hoy = moment();
    let edad = hoy.diff(fechaMoment, 'years');
    return edad;
  }

  // Método para cargar direcciones basadas en el término de búsqueda
  loadDirecciones(searchTerm: string) {
    this.buscarDireccionService.getDirecciones(searchTerm).subscribe((resp) => {
      this.listDirecciones = resp.data;
    });
  }

  onInputChanged(searchTerm: string) {
    this.loadDirecciones(searchTerm);
  }

  displayFn(option: IDataDireccion): string {
    return option && option.address ? option.address : '';
  }

  public displayAddress(direccion: any): string {
    return direccion ? direccion : ''; // Mostramos el atributo address
  }

  /* RESET CAMPOS FORMULARIO*/
  public soloLetras(event: KeyboardEvent) {
    this.utilServiceService.onlyLetters(event);
  }

  public formatAltura(event: KeyboardEvent) {
    this.utilServiceService.formatAltura(event);
  }

  public formatPeso(event: KeyboardEvent) {
    this.utilServiceService.formatPeso(event);
  }

  public soloNumeros(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value;
    value = value.replace(/[^0-9]/g, '');
    this.formRegistroPaciente.controls['edad'].setValue(value);
  }

  public restringirCaracteresEspeciales(event: KeyboardEvent) {
    this.utilServiceService.restringirCaracteresEspeciales(event);
  }

  public submitForm() {
    if (this.formRegistroPaciente.valid && this.formRegistroAcompanante.valid) {
      this.armadoRequest();
    }
  }

  armarReqAcompanante(): IDataReqAcompanante | null {
    if (this.acompanante.value == 'Si') {
      return {
        nombre: this.nombreAcom.value,
        apellido: this.apellidoAcom.value,
        edad: this.edadAcom.value.toString(),
        fechaNacimiento: this.fechaNacimientoAcom.value,
        idParentesco: this.parentezcoAcom.value,
        idTipoDocumento: this.tipoDocAcom.value,
        numeroDocumento: this.numDocAcom.value.toString(),
        telefono: this.telefonoAcom.value,
      };
    } else {
      return null;
    }
  }

  armarReqGuardarPaciente(): IReqDataPaciente {
    return {
      idAgendamiento: this._fichaClinicaStore.values.idAgendamiento!,
      idPaciente: this.idPacient,
      nombres: this.nombre.value,
      apellidos: this.apellido.value,
      fechaNacimiento: this.fechaNacimiento.value,
      edad: String(this.edad.value),
      idSexo: this.idSexo.value,
      idEstadoCivil: this.idEstadoCivil.value,
      idPaisNacimiento: this.paisNacimineto.value,
      idRegionNacimiento: this.ciudadNacimiento.value,
      idReligion: this.idReligion.value,
      idEtnia: this.etnia.value,
      idDireccion: this.idDireccionSeleccionada,
      idGradoInstruccion: this.idGradoInstruccion.value,
      ocupacion: this.ocupacion.value,
      telefono: this.formRegistroPaciente.get('numero')?.value,
      correoElectronico: this.email.value,
      idGrupoSanguineo: this.idGrupoSanguineo.value,
      peso: this.peso.value,
      altura: this.altura.value,
      direccionCompleta: this.direccionCompleta.value,
      numeroCasa: this.numeroCasa.value,
      acompaniante: this.armarReqAcompanante(),
    };
  }

  private armadoRequest() {
    const reqData = this.armarReqGuardarPaciente();

    if (this.formularioModificado()) {
      const dialogRef = this.dialog.open(ModalConfirmCambioDatosComponent, {
        data: { reqData },
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result && result.update) {
          this.guardarService(reqData);
        } else {
        }
      });
    } else {
      this.guardarService(reqData);
    }
  }

  private guardarService(req: IReqDataPaciente) {
    this.guardadoDatosRegistroService.postNuevaConsulta(req).subscribe({
      next: (resp) => {
        this.guardadoDatosResp = resp;

        this._updateSidebarInfo(req)

        if (resp) {
          this.isDataSaved = true;
          this.dataExistePaciente = {
            idTipoDocumento: this.dataExistePaciente.idTipoDocumento,
            numeroDocumento: this.dataExistePaciente.numeroDocumento,
            historiaClinica: this.dataExistePaciente.numeroDocumento,
            nombre: req.nombres,
            apellido: req.apellidos,
            email: req.correoElectronico,
            numero: req.telefono,
            tipoSeguro: this.dataExistePaciente.tipoSeguro,
            fechaNacimiento: req.fechaNacimiento,
            edad: req.edad,
            idSexo: req.idSexo,
            idEstadoCivil: req.idEstadoCivil,
            idReligion: req.idReligion,
            etnia: req.idEtnia,
            idGradoInstruccion: req.idGradoInstruccion,
            paisNacimineto: req.idPaisNacimiento,
            ciudadNacimiento: req.idRegionNacimiento,
            idGrupoSanguineo: req.idGrupoSanguineo,
            ocupacion: req.ocupacion,
            direccionCompleta: req.direccionCompleta,
            peso: req.peso,
            altura: req.altura,
            numeroCasa: req.numeroCasa
          };

          this.dataExisteAcompanante = req.acompaniante
            ? {
                apellidos: req.acompaniante.apellido,
                edad: req.acompaniante.edad,
                fechaNacimiento: req.acompaniante.fechaNacimiento,
                idParentesco: req.acompaniante.idParentesco,
                idTipoDocumento: req.acompaniante.idTipoDocumento,
                nombre: req.acompaniante.nombre,
                numeroDocumento: req.acompaniante.numeroDocumento,
                telefono: req.acompaniante.telefono,
              }
            : null;

          this.restablecerFormulario();

          this.savedDataPaciente.set(this.formRegistroPaciente.value);
          this.savedDataAcompanante.set(this.formRegistroAcompanante.value);

          this._fichaClinicaStore.updateMedicalHistoryBySection('identificacion', true);

          this.snackBar.openFromComponent(SnackInfoComponent, {
            data: {
              message: 'Los cambios han sido guardados con éxito.',
              type: SnackInfoType.SUCCESS,
            },
            duration: 3000, // Duración en milisegundos
          });
        }
      },
      error: () => {
        this.dialog.open(ModalErrorGeneralComponent);
      },
    });
  }

  private _updateSidebarInfo(data: IReqDataPaciente) {
    const dataExtPaciente: Partial<IDataExtPaciente> = {
      nombres: data.nombres,
      apellidos: data.apellidos,
      prevision: this.tipoPrevisionOptions().find((p) => p.idPrevision === this.tipoSeguro.value),
      correoElectronico: data.correoElectronico,
      telefono: data.telefono,
      tipoDocumento: this.tipoDocumentoOptions.find((t) => t.idTipoDocumento === this.idTipoDocumento.value),
      numeroDocumento: this.numeroDocumento.value,
    };
    this.ShareDataService.setExistePaciente(dataExtPaciente);
  }

  private formularioModificado(): boolean {
    const valoresActuales = this.formRegistroPaciente.getRawValue();
    if (!this.dataExistePaciente) {
      return false;
    }
    // Compara valores actuales formulario con precargar
    return Object.keys(this.dataExistePaciente).some((key) => {
      return (
        this.dataExistePaciente[key as keyof IDataFormularioPaciente] !==
        valoresActuales[key]
      );
    });
  }

  onDocAcomSelected(selectedValue: any) {
    switch (selectedValue) {
      case this.dniId():
        this.infoNumDocAcom.update(current => {
          current.label = 'N° Documento';
          current.inputValidate = this.manejoDataDni.bind(this);
          return current;
        })
        break;
      case this.pasaporteId():
        this.infoNumDocAcom.update(current => {
          current.label = 'Número pasaporte';
          current.inputValidate = this.manejoDataPasaporte.bind(this);
          return current;
        })
        break;
      default:
        this.infoNumDocAcom.update(current => {
          current.label = 'N° Documento';
          current.inputValidate = this.manejoDataPasaporte.bind(this);
          return current;
        })
        break;
    }
  }

  // Limpia los campos de id y añade los validadores
  addRequiredIdAcom() {
    this.numDocAcom.addValidators([Validators.required, Validators.minLength(8)]);
    this.numDocAcom.updateValueAndValidity();
  }

  manejoDataDni(event: Event) {
    this.utilServiceService.formatDni(event);
  }
  manejoDataPasaporte(event: Event) {
    this.utilServiceService.formatPasaporte(event);
  }

  onCancelarEdicion() {
    this.openConfirmarCancelar();
  }

  restablecerFormulario() {
    this.formRegistroPaciente.reset();
    this.formRegistroPaciente.patchValue(this.dataExistePaciente);


    if (this.dataExisteAcompanante) {
      this.showSeccionAcompanante = true;
      this.acompanante.setValue('Si');
      this.setDataAcompanante(this.dataExisteAcompanante);
    } else {
      this.showSeccionAcompanante = false;
    }
    this.cdr.detectChanges();
  }

  public openConfirmarCancelar() {
    const dialogRef = this.dialog.open(ModalConfirmComponent, {
      autoFocus: false,
      panelClass: 'custom-confirmation-dialog',
      width: '452px',
      data: {
        type: ModalConfirmType.WARNING,
        description: '¿Confirmas que deseas cancelar sin guardar?',
        config: {
          buttons: [
            { title: 'Volver', action: false },
            { title: 'Confirmar', action: true },
          ],
        },
      } as ModalConfirmProps,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.restablecerFormulario();
      }
    });
  }

  btnGuardarDisabled(): boolean {
    return (
      this.formRegistroPaciente.invalid ||
      this.formRegistroAcompanante.invalid ||
      this.formRegistroAcompanante.disabled ||
      this.isFormEqualAfterSave()
    );
  }

  isFormEqualAfterSave = computed(() => {
    let isFormEqual = false;
    isFormEqual = isEqual(this.savedDataPaciente(), this.formPaciente()) && isEqual(this.savedDataAcompanante(), this.formAcompanante());
    if (!isFormEqual) {

      this._fichaClinicaStore.updateMedicalHistoryBySection('identificacion', false);
    }

    if (isFormEqual) {
      this._fichaClinicaStore.updateMedicalHistoryBySection('identificacion', true);
    }

    return isFormEqual;
  })
}
