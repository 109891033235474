import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { IRespPaciente } from '../interfaces/paciente.interface';

@Injectable({
  providedIn: 'root',
})
export class PacienteService {

  _httpClient = inject(HttpClient)

  obtenerPacienteAtencion( idPaciente: string, idAgendamiento: string ): Observable<IRespPaciente> {
    return this._httpClient.get<IRespPaciente>(`${environment.baseURL}paciente/obtener-paciente-atencion/${idPaciente}/${idAgendamiento}`);
  }
}
