import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Inject,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule, MatIconButton } from '@angular/material/button';
import { CommonModule } from '@angular/common';

import { IRespDetalleNuevaConsulta } from '../../../../shared/interfaces/tratamiento/detalle-nueva-consulta.interface';
import { DialogRef } from '@angular/cdk/dialog';
import { MatIcon } from '@angular/material/icon';
import { UtilServiceService } from '../../../../shared/services/util-service.service';
import { Router } from '@angular/router';

@Component({
  selector: 'soph-modal-detalle-consulta',
  standalone: true,
  imports: [
    MatButtonModule,
    CommonModule,
    MatDialogModule,
  ],
  templateUrl: './modal-detalle-consulta.component.html',
  styleUrl: './modal-detalle-consulta.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalDetalleConsultaComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: IRespDetalleNuevaConsulta,
    private readonly _utilService: UtilServiceService,
    private router: Router
  ) {}

  public readonly dialogRef = inject(DialogRef);
  fechaconsulta!: string;
  horaConsulta!: string;
  tipoAtencion!: string;
  public presecial = 'Presencial';
  public domiciliaria = 'Domiciliaria';
  public telemedicina = 'Telemedicina';

  ngOnInit(): void {
    this.data;
    this.getTipoAtencion();
  }

  getTipoAtencion() {
    this.tipoAtencion = this._utilService.formatUpperCaseFirstLetter(
      this.data.tipoATencion
    );
  }

  public editarConsulta() {
    this.dialogRef.close(); 
    return this.router.navigate(['/ingreso']);
  }
}
