import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Type,
} from '@angular/core';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { MenuLateralInfoComponent } from './menu-lateral-info/menu-lateral-info.component';
import { RegistroComponent } from './registro/registro.component';
import { AnamnesisComponent } from './anamnesis/anamnesis.component';
import { ExamenesComponent } from './examenes/examenes.component';
import { DiagnosticoComponent } from './diagnostico/diagnostico.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { HeaderSophiaComponent } from '../../common/components/header-sophia/header-sophia.component';
import { Router } from '@angular/router';
import { TratamientoComponent } from './tratamiento/tratamiento.component';
import { FichaClinicaStore } from '../../../shared/stores/ficha-clinica/ficha-clinica.store';
import { FichaClinicaService } from '../../services/ficha-clinica.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalErrorGeneralComponent } from '../../common/modal-error-general/modal-error-general.component';
import { ShareDataService } from '../../../shared/services/share-data.service';
import { BehaviorSubject } from 'rxjs';
import { IDataPacienteForm } from '../../../shared/interfaces/paciente-form.interface';
import { CommonModule } from '@angular/common';
import { UtilServiceService } from '../../../shared/services/util-service.service';

export enum TabPosition {
  Identificacion,
  Anamnesis,
  Diagnostico,
  Tratamiento,
  Examenes,
}

@Component({
  selector: 'soph-ficha-clinica',
  standalone: true,
  imports: [
    CommonModule,
    MatTabsModule,
    MatButtonModule,
    MatIconModule,
    HeaderSophiaComponent,
    MenuLateralInfoComponent,
    RegistroComponent,
    AnamnesisComponent,
    DiagnosticoComponent,
    TratamientoComponent,
    ExamenesComponent,
  ],
  templateUrl: './ficha-clinica.component.html',
  styleUrl: './ficha-clinica.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FichaClinicaComponent {
  readonly dialog = inject(MatDialog);
  dataNuevaConsulta = new BehaviorSubject<any | null>(null);
  horaNuevaConsulta!: string;
  fechaNuevaConsulta!: string;
  tipoAtencion!: string;
  public presecial = 'Presencial'
  public domiciliaria = 'Domiciliaria'
  public telemedicina = 'Telemedicina'

  existePaciente = new BehaviorSubject<any | null>(null);
  nombrePaciente!: string;
  apellidosPaciente!: string;
  historiaClinica!: string;
  dniPaciente!: string;
  correoPaciente!: string;
  telefonoPaciente!: string;
  previsionPaciente!: string;
  tipoDocumentoPaciente!: string;
  valueTipoDocumentoPaciente!: string;
  constructor(
    private readonly _router: Router,
    private readonly _fichaClinicaStore: FichaClinicaStore,
    private readonly _fichaClinicaService: FichaClinicaService,
    private readonly _shareDataService: ShareDataService,
    private readonly _utilService: UtilServiceService
  ) {
    if (!this._fichaClinicaStore.values.idAgendamiento) {
      this.irAregistro();
    } else {
      this.getFichaClinica();
    }
  }

  ngOnInit(): void {
    this.getDataNuevaConsulta();
    this.getDataPaciente();
  }

  private getDataNuevaConsulta() {
    this.horaNuevaConsulta =this._fichaClinicaStore.values.horaAtencion
    this.fechaNuevaConsulta =this._fichaClinicaStore.values.fechaAtencion
    this.tipoAtencion =this._utilService.formatUpperCaseFirstLetter(this._fichaClinicaStore.values.tipoAtencion);
  }

  private getFichaClinica() {
    this._fichaClinicaService
      .info(this._fichaClinicaStore.values.idAgendamiento!)
      .subscribe({
        next: (response) => {
          if (response.data) {
            this._fichaClinicaStore.updateStore({
              ...this._fichaClinicaStore.values,
              estado: response.data.estado,
            });
          }
        },
        error: () => {
          this.dialog.open(ModalErrorGeneralComponent);
        },
      });
  }

  private getDataPaciente () {
    this._shareDataService.getDataExistePaciente().subscribe({
      next: (data) => {
        if(data){
          this.nombrePaciente = data.nombres;
          this.apellidosPaciente = data.apellidos;
          this.previsionPaciente = data.prevision.nombre
          this.correoPaciente = data.correoElectronico;
          this.telefonoPaciente = data.telefono;
          this.tipoDocumentoPaciente = data.tipoDocumento?.nombre;
          this.valueTipoDocumentoPaciente = data.numeroDocumento;

        }
      },
    });
  }

  public irAregistro() {
    const vaciarAgendamiento: IDataPacienteForm = {
      apellidos: '',
      casaDepto: '',
      direccion: '',
      direccionCompleta: '',
      email: '',
      fechaSeleccionada: '',
      horaSeleccionada: '',
      institucion: '',
      link: '',
      nombre: '',
      numero: '',
      numeroDocumento: '',
      numeroDocumentoOtros: '',
      numeroPasaporte: '',
      prevision: '',
      tipoAtencion: '',
      tipoDocumento: '',
    };
    this._shareDataService.setFormData(vaciarAgendamiento);
    this._router.navigate(['/ingreso'], { replaceUrl: true });
  }

  public onSelectedTabChange(event: MatTabChangeEvent) {
    const { index } = event;

    this._fichaClinicaStore.updateStore({
      ...this._fichaClinicaStore.values,
      tabActualSeleccionado: index,
    });
  }

}
