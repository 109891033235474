import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ObtenerArchivosExamenesService {
  private apiUrl = environment.baseURL + 'files';
  constructor(private httpClient: HttpClient) {}

  getArchivosCargados(url: string): void {
    const params = new HttpParams().set('url', url);

    this.httpClient
      .get(`${this.apiUrl}/download-file`, {
        params,
        responseType: 'blob'
      })
      .subscribe((blob) => {
        const objectUrl = URL.createObjectURL(blob);
        window.open(objectUrl, '_blank');

        setTimeout(() => URL.revokeObjectURL(objectUrl), 10000);
      });
  }
}
