import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { IRespEvent } from '../interfaces/tratamiento/evento-agendamiento.interface';

@Injectable({
  providedIn: 'root'
})
export class ObtenerAgendamientosMedicosService {
    private apiUrl = environment.baseURL + 'eventos';

  constructor(private httpClient: HttpClient) { }

  getEventos( fechaInicio: string, fechaFin: string): Observable<IRespEvent> {
    const params = new HttpParams()
      .set('fechaInicio', fechaInicio)
      .set('fechaFin', fechaFin)
    return this.httpClient.get<IRespEvent>(`${this.apiUrl}`, { params });
  }
  
}
