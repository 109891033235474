import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';

@Component({
  selector: 'soph-modal-nuevo-agendamiento-creado',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule, MatIconModule],
  templateUrl: './modal-nuevo-agendamiento-creado.component.html',
  styleUrl: './modal-nuevo-agendamiento-creado.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalNuevoAgendamientoCreadoComponent {
  showCamposDomicilio = true;
  showCamposTelemedicina = false;
  showCampoPresnecial = false;
  direccion: string | null = null;
  direc : string = ''
  casaDpto : string = ''
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.showTipoConsulta();
  }


  showTipoConsulta() {
    if(this.data){
      switch (this.data.tipoConsultaSelec
      ) {
        case 'PRESENCIAL':
          this.showCampoPresnecial = true;
          this.showCamposDomicilio = false;
          this.showCamposTelemedicina = false;
          break;
  
        case 'VISITA DOMICILIARIA':
          this.showCamposDomicilio = true;
          this.showCampoPresnecial = false;
          this.showCamposTelemedicina = false;
          break;
        case 'TELEMEDICINA':
          this.showCamposTelemedicina = true;
          this.showCampoPresnecial = false;
          this.showCamposDomicilio = false;
    
          break;
      }
    }
  }

  public irAFicha() {
    return this.router.navigate(['/ficha']);
  }
}
