import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IRespDataAtencion } from '../interfaces/historicoConsulta.interface';

@Injectable({
  providedIn: 'root',
})
export class HistoricoConsultaService {
  constructor(private httpClient: HttpClient) {}

  getResumenConsulta(idAgendamiento: string): Observable<IRespDataAtencion> {
    const params = new HttpParams().set('idAgendamiento', idAgendamiento);
    return this.httpClient.get<IRespDataAtencion>(
      environment.baseURL + `historico-consultas`,
      { params }
    );
  }
}
