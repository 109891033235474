import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  inject,
  Input,
  model,
  OnInit,
  Renderer2,
  signal,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocomplete, MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { AbstractControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';

export interface Value {
  [key: string]: any;
}

@Component({
  selector: 'soph-input-with-chip-form-field-v2',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatChipsModule,
    MatIconModule,
    MatAutocompleteModule,
    MatDividerModule,
    MatInputModule,
    ScrollingModule,
    CommonModule,
  ],
  providers: [],
  templateUrl: './input-with-chip-form-field-v2.component.html',
  styleUrl: './input-with-chip-form-field-v2.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputWithChipFormFieldV2Component<T extends Value> implements OnInit, AfterViewInit {
  @Input() formGroup!: FormGroup;
  @Input() controlName!: string;
  @Input() label!: string;
  @Input() placeholder!: string;
  @Input() values!: WritableSignal<T[]>;
  @Input() idField!: keyof T;
  @Input() viewField!: keyof T;

  readonly currentValue = model('');
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  readonly data = signal<Array<any>>([]);


  readonly pageSize = 20;
  currentPage = signal(0);
  readonly paginatedValues = computed(() => {
    const endIndex = this.currentPage() === 0 ? this.pageSize : this.currentPage() * this.pageSize;
    return this.values().slice(0, endIndex);
  });

  readonly filteredValues = computed(() => {
    const currentValue = this.currentValue().toLowerCase();
    return currentValue ? this.values().filter((value) => value[this.viewField].toLowerCase().includes(currentValue)) : this.paginatedValues();
  });

  readonly announcer = inject(LiveAnnouncer);

  readonly errorMessage: WritableSignal<{ [key: string]: string }> = signal({});

  @ViewChild('inputSearch', { static: false }) inputSearch!: ElementRef;
  @ViewChild('auto', { static: false }) autocomplete!: MatAutocomplete;

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    this.autocomplete.opened.subscribe(() => {
      this.setupScrollListener();
    });
  }

  onPanelClosed() {
    this.currentPage.set(0);
  }

  private setupScrollListener(): void {
    setTimeout(() => {
      const panel = this.autocomplete.panel?.nativeElement;

      if (panel) {
        this.renderer.listen(panel, 'scroll', this.onScroll.bind(this));
        this.renderer.listen(panel, 'close', () => {});
      }
    }, 0);
  }

  ngOnInit(): void {
    this.formGroup.get(this.controlName)?.valueChanges.subscribe((values) => {
      if (values) {
        const filterValues = this.values().filter((value) => values.includes(value[this.idField]));

        this.data.set(filterValues);
      } else {
        this.data.set([]);
      }
    });
  }

  onScroll(event: Event): void {
    const element = event.target as HTMLElement;

    this.currentPage.update((current) => current + 1);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.value;

    if (
      value &&
      !this.data()
        .map((values) => values[this.idField])
        .includes(value[this.idField])
    ) {
      this.data.update((values) => [...values, value]);

      this.formGroup.get(this.controlName)?.setValue(this.data().map((value) => value[this.idField]));
    }

    this.inputSearch.nativeElement.value = '';
    this.currentValue.set('');

    event.option.deselect();
  }

  remove(value: string): void {
    this.data.update((values) => {
      const index = values.indexOf(value);
      if (index < 0) {
        return values;
      }

      values.splice(index, 1);

      this.formGroup.get(this.controlName)?.setValue(values.map((value) => value[this.idField]));

      this.announcer.announce(`Removed ${value}`);

      return [...values];
    });
  }

  updateErrorMessage(fieldName: string) {
    const control: AbstractControl | null = this.formGroup.get(fieldName);
    const currentErrorMessages = this.errorMessage() as any;

    if (control?.hasError('required')) {
      currentErrorMessages[fieldName] = 'Este campo es obligatorio';
    } else if (control?.hasError('email')) {
      currentErrorMessages[fieldName] = 'No es un correo electrónico válido';
    } else if (control?.hasError('maxlength')) {
      currentErrorMessages[fieldName] = 'Ha excedido el máximo de caracteres permitidos';
    } else {
      currentErrorMessages[fieldName] = '';
    }

    this.errorMessage.set({ ...currentErrorMessages });
  }

  isRequired(fieldName: string) {
    const control: AbstractControl | null = this.formGroup.get(fieldName);
    return control?.hasError('required') ?? false;
  }

  isInvalid(fieldName: string) {
    const control: AbstractControl | null = this.formGroup.get(fieldName);
    return control?.invalid ?? false;
  }
}
