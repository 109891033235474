import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  Signal,
  signal,
  WritableSignal,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isEqual } from 'lodash';
import { asapScheduler, distinctUntilChanged } from 'rxjs';
import { InputWithChipFormFieldV2Component } from '../../../../shared/components/input-with-chip-form-field-v2/input-with-chip-form-field-v2.component';
import {
  ModalConfirmComponent,
  ModalConfirmProps,
  ModalConfirmType,
} from '../../../../shared/components/modal-confirm/modal-confirm.component';
import {
  SnackInfoComponent,
  SnackInfoProps,
  SnackInfoType,
} from '../../../../shared/components/snack-info/snack-info.component';
import { TextareaWithSpeechToTextFormFieldComponent } from '../../../../shared/components/textarea-with-speech-to-text-form-field/textarea-with-speech-to-text-form-field.component';
import { UploadArchivosComponent } from '../../../../shared/components/upload-archivos/upload-archivos.component';
import { EstadoFichaClinica } from '../../../../shared/enums';
import { fichaClinicaFinalizada } from '../../../../shared/helpers/ficha-clinica.helper';
import { UploadArchivos } from '../../../../shared/interfaces/cargaArchivo.interface';
import {
  ExamenesPaciente,
  IDataExamn,
  IDataTypeExamn,
} from '../../../../shared/interfaces/examenes.interface';
import { GuardadoExamenesService } from '../../../../shared/services/guardado-examenes.service';
import { TabExamenesService, UploadedFile } from '../../../../shared/services/tab-examenes.service';
import { FichaClinicaStore } from '../../../../shared/stores/ficha-clinica/ficha-clinica.store';
import { FichaClinicaService } from '../../../services/ficha-clinica.service';
import { ResumenClinicoService } from '../../../services/reportes/resument-clinico/resumen-clinico.service';
import { TabPosition } from '../ficha-clinica.component';

interface ExamenesForm {
  examenLaboratorio: FormControl<Array<any> | null>;
  examenImagen: FormControl<Array<any> | null>;
  fileExam: FormControl<Array<any> | null>;
  diagnosticoExamen: FormControl<string | null>;
}

interface ExamenesFormValues {
  examenLaboratorio: any;
  examenImagen: any;
  fileExam: ExamenesPaciente[];
  diagnosticoExamen: any;
}

@Component({
  selector: 'soph-examenes',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    UploadArchivosComponent,
    TextareaWithSpeechToTextFormFieldComponent,
    InputWithChipFormFieldV2Component,
  ],
  templateUrl: './examenes.component.html',
  styleUrl: './examenes.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExamenesComponent {
  readonly path = 'examenes';
  readonly dialog = inject(MatDialog);
  readonly snackBar = inject(MatSnackBar);
  readonly resumenClinico = inject(ResumenClinicoService);

  public typeExamn = signal<Array<IDataTypeExamn>>([]);

  public laboratorioTypeExam: string | null = null;
  public imagenesTypeExam: string | null = null;

  public examsLaboratorio = signal<Array<IDataExamn>>([]);
  public examsImagen = signal<Array<IDataExamn>>([]);

  readonly isAllTabsCompleted = signal(false);

  public form: FormGroup<ExamenesForm>;
  public savedFormData: WritableSignal<any> = signal<any>({});
  public currentFormData: WritableSignal<any> = signal<any>({});
  public isFormEqual = computed(() => {
    return isEqual(this.savedFormData(), this.currentFormData())
  })

  readonly errorMessage: WritableSignal<{ [key: string]: string }> = signal({});

  private examenes = signal<ExamenesFormValues | null>(null);

  public fileName: string = '';
  public file: UploadArchivos[] = [];
  public hasDocumentUrl: boolean = false;
  public documentUrl: string = '';
  isUploaderDisabled: boolean = false;
  isBtnGuardarDisabled: Signal<boolean> = computed(() => this.isFormEqual() && !this.isAllTabsCompleted());

  public examenesExistentes: WritableSignal<UploadedFile[]> = signal([]);

  constructor(
    private readonly _fb: FormBuilder,
    private readonly _fichaClinicaStore: FichaClinicaStore,
    private readonly _fichaClinicaService: FichaClinicaService,
    private readonly _tabExamenesService: TabExamenesService,
    private readonly _guardadoExamenesService: GuardadoExamenesService
  ) {
    this.form = this._fb.group<ExamenesForm>({
      examenImagen: new FormControl<Array<any> | null>(null, Validators.compose([])),
      examenLaboratorio: new FormControl<Array<any> | null>(null, Validators.compose([])),
      fileExam: new FormControl<Array<any> | null>(null, Validators.compose([])),
      diagnosticoExamen: new FormControl<string | null>(null, Validators.compose([Validators.maxLength(1000)])),
    });

    this._fichaClinicaStore.state$.pipe(distinctUntilChanged()).subscribe((values) => {
      if (values.tabActualSeleccionado === TabPosition.Examenes) {
        asapScheduler.schedule(() => {
          this.isAllTabsCompleted.set(Object.values(values.historial).every((value) => value === true) && !fichaClinicaFinalizada(values.estado || ''));
        });
      }

      if (values.estado === 'REALIZADA') {
        this.isUploaderDisabled = true;
        this.form.disable();
      }
    });

    this._fichaClinicaStore.updateMedicalHistoryBySection('examenes', true);
  }

  ngOnInit() {
    this.form.valueChanges.subscribe((newValues) => {
      this.currentFormData.set(newValues);
    });
    this.setTypesExams();
  }

  onExamenSelected(file: any): void {
    this.file = file;
  }

  public updateErrorMessage(fieldName: string) {
    const control: AbstractControl | null = this.form.get(fieldName);
    const currentErrorMessages = this.errorMessage();

    if (control?.hasError('required')) {
      currentErrorMessages[fieldName] = 'Este campo es obligatorio';
    } else {
      currentErrorMessages[fieldName] = '';
    }

    this.errorMessage.set({ ...currentErrorMessages });
  }

  public isRequired(fieldName: string) {
    const control: AbstractControl | null = this.form.get(fieldName);
    return control?.hasError('required') ?? false;
  }

  public isInvalid(fieldName: string) {
    const control: AbstractControl | null = this.form.get(fieldName);
    return control?.invalid ?? false;
  }

  public revertChanges() {
    if (this.examenes()) {
      this.form.patchValue({
        ...this.examenes(),
      });
    } else {
      this.form.reset();
    }
  }

  public onSave(form: FormGroup) {
    this.savedFormData.set(this.form.value);
    const reqGuardadoExam = {
      examenesImagen: this.form.get('examenImagen')?.value,
      examenesLaboratorio: this.form.get('examenLaboratorio')?.value,
      diagnosticoExamenes:  this.form.get('diagnosticoExamen')?.value,
    }
    if (!form.valid) {
      return;
    }
    this.guardadoExamenes(this._fichaClinicaStore.values.idAgendamiento!, reqGuardadoExam);
    if(this.isAllTabsCompleted()){
      this.onFinishAppointmentConfirmation();
    }
  }

  private onFinishAppointmentConfirmation() {
    const dialogRef = this.dialog.open(ModalConfirmComponent, {
      autoFocus: false,
      panelClass: 'custom-confirmation-dialog',
      width: '550px',
      disableClose: true,
      data: {
        type: ModalConfirmType.WARNING,
        description: '¿Confirmas que deseas finalizar la consulta?',
        message: 'Los datos ingresados se guardarán y solo algunos datos específicos podrán ser editados posteriormente.',
        config: {
          buttons: [
            { title: 'Volver', action: false },
            { title: 'Finalizar consulta', action: true },
          ],
        },
      } as ModalConfirmProps,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (typeof result === 'boolean' && result) {
        this.onFinishAppointment();
      }
    });
  }

  private onFinishAppointment() {
    this._fichaClinicaService.finish(this._fichaClinicaStore.values.idAgendamiento!).subscribe({
      next: () => {
        this._fichaClinicaStore.updateStore({
          ...this._fichaClinicaStore.values,
          estado: EstadoFichaClinica.REALIZADA,
        });

        this.onFinishAppointmentFinished();
        this.isUploaderDisabled = true;
        this.form.disable();
      },
      error: () => {
        /*this.dialog.open(ModalErrorGeneralComponent);*/
        this.snackBar.openFromComponent(SnackInfoComponent, {
          data: {
            message: 'No pudimos guardar los cambios, por favor intenta nuevamente.',
            type: SnackInfoType.ERROR,
          } as SnackInfoProps,
        });
      },
    });
  }

  private onFinishAppointmentFinished() {
    const dialogRef = this.dialog.open(ModalConfirmComponent, {
      autoFocus: false,
      panelClass: 'custom-confirmation-dialog',
      width: '452px',
      disableClose: true,
      data: {
        type: ModalConfirmType.SUCCESS,
        description: 'La consulta ha sido finalizada y guardada con éxito',
        message: 'La ficha clínica de esta consulta está lista para ser revisada cuando lo necesites',
        config: {
          buttons: [{ title: 'Aceptar', action: true }],
        },
      } as ModalConfirmProps,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (typeof result === 'boolean' && result) {
        this.resumenClinico.openResumenClinicoModal();
      }
    });
  }

  private setTypesExams() {
    this._tabExamenesService.getTypesExams().subscribe((typeExams) => {
      if (typeExams.success) {
        const exams = typeExams.data;
        const laboratorio = exams.find((exam: any) => exam.descripcion === 'Laboratorio');
        const imagenes = exams.find((exam: any) => exam.descripcion === 'Imágenes');
        this.laboratorioTypeExam = laboratorio ? laboratorio.idTipoExamen : null;
        this.imagenesTypeExam = imagenes ? imagenes.idTipoExamen : null;
      }
      this.setExams();
    });
  }
  private setExams() {
    this._tabExamenesService.getExamsByType().subscribe((response) => {
      if (response.success) {
        const examsData = response.data;
        const laboratorioExams = examsData
          .filter((examType: any) => examType.idTipoExamen === this.laboratorioTypeExam)
          .flatMap((examType: any) => examType.examenes);
        const imagenesExams = examsData
          .filter((examType: any) => examType.idTipoExamen === this.imagenesTypeExam)
          .flatMap((examType: any) => examType.examenes);
        this.examsLaboratorio.set(laboratorioExams);
        this.examsImagen.set(imagenesExams);

        if(this._fichaClinicaStore.values.idAgendamiento){
          this.getExamenesAtencion(this._fichaClinicaStore.values.idAgendamiento!);
        }
      }
    });
  }

  getExamenesAtencion(idAgendamiento:string){
    this._tabExamenesService.getExamenesAtencion(idAgendamiento!).subscribe((response) => {
      if(response.success){
        const data: ExamenesFormValues = {
          examenImagen: response.data.examenesAtencion.imagenes,
          examenLaboratorio: response.data.examenesAtencion.laboratorio,
          fileExam: response.data.examenesPaciente,
          diagnosticoExamen: response.data.diagnostico,
        }
        this.examenesExistentes.set(data.fileExam.map((file: ExamenesPaciente) => ({
          fileName: file.nombreArchivo,
          destination: file.url,
        })));
        this.form.patchValue(data);
      }
    });
  }

  guardadoExamenes(idAgendamiento:string, reqExam: any){
    this._guardadoExamenesService.postGuardarExamenes(idAgendamiento, reqExam).subscribe({
      next: (_) => {
      },
      error: (err) => {
        throw err;
      },
    });
  }
}
