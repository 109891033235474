<soph-header-sophia></soph-header-sophia>

<div class="header-container">
  <p class="titulo-agenda">Tu agenda</p>
</div>

<div class="header-container2">
  <button mat-fab extended (click)="openModalNuevaConsulta()">
    <mat-icon>calendar_month</mat-icon>
    Nueva consulta
  </button>

  <div class="container-tipo-consulta">
    <div class="chip-tipo-consulta">
      <div style="display: flex; gap: 5px">
        <img
          src="assets/icons/house.svg"
          style="display: table-cell"
          alt="house"
        />
        <p>Visita domiciliaria</p>
      </div>
    </div>
    <div class="chip-tipo-presnecial">
      <div style="display: flex; gap: 5px">
        <img
          src="assets/icons/house.svg"
          style="display: table-cell"
          alt="house"
        />
        <p>Presencial</p>
      </div>
    </div>
    <div class="chip-tipo-telemedicina">
      <div style="display: flex; gap: 5px">
        <img
        src="assets/icons/telemedicina.svg"
          style="display: table-cell"
          alt="house"
        />
        <p>Telemedicina</p>
      </div>
    </div>
  </div>

  <div>
    <button class="btn_bloqueo" mat-fab extended
    (click)="openBloqueoHorarios()">
      <mat-icon style="color: #d96704 !important;">lock</mat-icon>
      Bloquear horario
    </button>
  </div>
</div>

<div class="container">
  <div class="column right">
    <full-calendar [options]="calendarOptions"></full-calendar>
  </div>
</div>
