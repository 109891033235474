<div class="col-md-12 layout-menu-lateral">
  <div class="card-paciente-datos">
    <div class="card-header">
      <span class="texto-info">Paciente</span>
    </div>
    <div class="card-body">
      <div class="patient-info">
        <div class="patient-avatar">
          <img src="assets/icons/Persona_outline.svg" alt="" />
        </div>
        <div class="patient-details">
          <p class="texto-card">
            Nombre: {{ nombrePaciente }} {{ apellidosPaciente }}
          </p>
          <p class="texto-card" style="margin-bottom: 0">
            Historia clínica: {{ valueTipoDocumentoPaciente }}
          </p>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div style="margin-top: 20px" *ngIf="isExpanded">
        <p class="texto-card-panel">
          {{ tipoDocumentoPaciente }}: {{ valueTipoDocumentoPaciente }}
        </p>
        <p class="texto-card-panel">Correo: {{ correoPaciente }}</p>
        <p class="texto-card-panel">Teléfono: {{ telefonoPaciente }}</p>
        <p class="texto-card-panel">Tipo de seguro: {{ previsionPaciente }}</p>
      </div>
      <mat-accordion>
        <mat-expansion-panel
          (opened)="isExpanded = true"
          (closed)="isExpanded = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title class="text-panel">{{
              isExpanded ? "Ver menos" : "Ver más"
            }}</mat-panel-title>
          </mat-expansion-panel-header>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>

  @if (dataHistorial360) {
  <div class="history-360">
    <button (click)="openHistory360()" class="history-360-btn">
      <img ngSrc="assets/images/Logo-mini.svg" alt="" height="17" width="17" />
      <span> Ver Historial 360&deg; </span>
    </button>
  </div>
  }

  @if (contadorAtencionesRealizadas()) {


  <div class="row info-consultas">
    <h1 class="texto-info" style="margin-bottom: 6px; font-size: 20px">
      Historial de consultas ({{ contadorAtencionesRealizadas().length }})
    </h1>
    <p>Selecciona una consulta y podrás visualizar la ficha clínica asociada</p>
  </div>

  <div class="contenedor-consultas">
    <div *ngFor="let atencion of atenciones()" class="card-info-estado">
      <div class="contenedor-2">
        <div
          class="row consulta-realizada"
          [ngClass]="{
            realizada: atencion.estado === 'REALIZADA',
            suspendida: atencion.estado === 'SUSPENDIDA',
            anulada: atencion.estado === 'ANULADA'
          }"
        >
          <img
            *ngIf="atencion.estado == 'REALIZADA'"
            src="assets/icons/check_icon.svg"
            alt=""
          />
          <img
            *ngIf="atencion.estado == 'ANULADA'"
            src="assets/icons/cancel.svg"
            alt=""
          />
          <img
            *ngIf="atencion.estado == 'SUSPENDIDA'"
            src="assets/icons/delete.svg"
            alt=""
          />
          <p class="estilo-texto-chip">
            Consulta {{ capitalizeName(atencion.estado) }}
          </p>
        </div>
        <h1 class="texto-info-consulta">
          {{ atencion.fechaAtencion }} - {{ atencion.horaAtencion }}
        </h1>
      </div>
      <div *ngIf="atencion.estado === 'REALIZADA'" class="row detalle-consulta">
        <img
          src="assets/icons/eye_outline.svg"
          alt=""
          style="margin-left: 9px"
        />
        <p
          class="style-hipervinculo"
          (click)="openResumenConsulta(atencion.idAgendamiento)"
        >
          Ver consulta
        </p>

        <img
          *ngIf="atencion.hasReceta"
          src="assets/icons/eye_outline.svg"
          style="margin-left: 38px"
          alt=""
        />
        <p
          class="style-hipervinculo"
          *ngIf="atencion.hasReceta"
          (click)="showReceta(atencion.idAgendamiento)"
        >
          Ver receta médica
        </p>
      </div>
    </div>
  </div>
}
</div>
