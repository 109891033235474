
<div class="card-container">
  <div class="card-header">
    <img src="assets/icons/perfil-detalle-consulta.svg" alt="" />
    <span class="contact-name texto-info">{{ data.nombrePaciente }}</span>
  </div>
  <div class="card-body">
    <div class="card-row" style="gap: 40px">
      <div style="display: flex; gap: 12px;">
        <img
        src="assets/icons/calendar-info-consulta.svg"
        style="display: table-cell"
        alt="house"
      />
      <span class="texto-light">{{ data.fechaAtencion }}</span>
      </div>
      <div style="display: flex; gap: 12px">
        <img
        src="assets/icons/clock-info-consulta.svg"
        style="display: table-cell"
        alt="house"
      />
      <span class="texto-light">{{ data.horaAtencion }}</span>
      </div>
    </div>

    <div class="card-row texto-info" style="gap: 12px">
      <img
        src="assets/icons/tel-info-consulta.svg"
        style="display: table-cell"
        alt="house"
      />
      <span class="texto-light">{{data.telefonoPaciente}}</span>
    </div>


    <div
    class=" card-row chip-tipo-consulta"
    [ngClass]="{
      presencial: tipoAtencion === presecial,
      telemedicina: tipoAtencion === telemedicina,
      domiciliaria: tipoAtencion === domiciliaria
    }"
  >
    <div>
      <img
        src="assets/icons/house.svg"
        style="display: table-cell"
        *ngIf="tipoAtencion === domiciliaria"
        alt="house"
      />
    </div>
    <div>
      <img
        src="assets/icons/hospital.svg"
        style="display: table-cell"
        *ngIf="tipoAtencion === presecial"
        alt="hospital"
      />
    </div>
    <div>
      <img
        src="assets/icons/telemedicina.svg"
        style="display: table-cell"
        *ngIf="tipoAtencion === telemedicina"
        alt="telemedicina"
      />
    </div>
    <p class="style-texto-chip" style="margin-bottom: 0">
      {{ tipoAtencion }}
    </p>
  </div>

    <div *ngIf="data.tipoATencion === 'DOMICILIARIA' || 'PRESENCIAL'" class="card-row">
      <img
      src="assets/icons/pin-info-consulta.svg"
      style="display: table-cell"
      alt="house"
    />
      <span class="texto-light" style="margin-left: 12px;"
        >{{data.detalleAtencion}}</span
      >
    </div>
  </div>
  <div class="card-footer">
    <button class="btn edit-btn texto-info"
    (click)="editarConsulta()">Editar consulta</button>
    <button class="btn go-btn texto-info">Ir a la consulta</button>
  </div>
  <button class="close-btn">
    <i class="fas fa-times"></i>
  </button>
  <div class="section-anular">
    <img
    src="assets/icons/anular-info-consulta.svg"
    style="display: table-cell"
    alt="house"
  />
  <p class="text-anular-consulta">Anular consulta</p>
  </div>
</div>
