import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {AuthenticationService} from "../../pages/services/authentication.service";
import {Observable} from "rxjs";
import {IDataEnitas, IRespEtnias} from "../interfaces/etnias.interface";
import {IRespReligion} from "../interfaces/religion.interface";
import {IResGrupoSanguineo} from "../interfaces/grupo-sanguineo.interface";
import {IResOcupaciones} from "../interfaces/ocupacion.interface";
import {IResEstadoCivil} from "../interfaces/estado-civil.interface";
import {IResGenero} from "../interfaces/genero.interface";
import {IResRegiones} from "../interfaces/regiones.interface";
import { IRespPaises } from '../interfaces/paises.interface';

@Injectable({
  providedIn: 'root'
})
export class ServiceFormIdentificacionService {

  private apiUrl = 'https://dev.api.sophia.seiza-ti.cl/api/v1/datos-personales';
  constructor(private httpClient: HttpClient) { }



  getEtnias(): Observable<IRespEtnias>{
    return this.httpClient.get<IRespEtnias>(this.apiUrl+'/etnias');
  }

  getReligion(): Observable<IRespReligion>{
    return this.httpClient.get<IRespReligion>(this.apiUrl+'/religion');
  }

  getGrupoSanguineo(): Observable<IResGrupoSanguineo>{
    return this.httpClient.get<IResGrupoSanguineo>(this.apiUrl+'/grupos-sanguineos');
  }

  geInstrucciones(): Observable<IResOcupaciones>{
    return this.httpClient.get<IResOcupaciones>(this.apiUrl+'/grado-instruccion');
  }

  getEstadoCivil(): Observable<IResEstadoCivil>{
    return this.httpClient.get<IResEstadoCivil>(this.apiUrl+'/estado-civil');
  }

  getGenero(): Observable<IResGenero>{
    return this.httpClient.get<IResGenero>(this.apiUrl+'/sexo');
  }

  getRegiones(): Observable<IResRegiones>{
    return this.httpClient.get<IResRegiones>(this.apiUrl+'/regiones');
  }

  getPaises(): Observable<IRespPaises>{
    return this.httpClient.get<IRespPaises>(this.apiUrl+'/paises');
  }
}
