import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  effect,
  EventEmitter,
  inject,
  Input,
  Output,
  signal,
  SimpleChanges,
  WritableSignal,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  TabExamenesService,
  UploadedFile,
} from '../../services/tab-examenes.service';
import { FichaClinicaStore } from '../../stores/ficha-clinica/ficha-clinica.store';
import { firstValueFrom } from 'rxjs';
import {
  SnackInfoComponent,
  SnackInfoType,
} from '../snack-info/snack-info.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ObtenerArchivosExamenesService } from '../../services/obtener-archivos-examenes.service';

@Component({
  selector: 'soph-upload-archivos',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './upload-archivos.component.html',
  styleUrl: './upload-archivos.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadArchivosComponent {
  readonly snackBar = inject(MatSnackBar);

  @Input() control: FormControl = new FormControl<any>({});
  @Input() error = 'Campo requerido';
  @Input() hasDocumentUrl = false;
  @Input() fileName: string = '';
  @Input() url: string = '';
  @Input() isDisabled: boolean = false;
  @Input() examenesExistentes?: WritableSignal<UploadedFile[]> = signal([]);

  @Output() fileNameChange: EventEmitter<string> = new EventEmitter<string>();

  selectedImage: string | ArrayBuffer | null = null;
  showMessage: boolean = false;
  showEye: boolean = false;
  showDelete: boolean = false;
  esp: boolean = true;
  currentLanguage = 'es';
  hoverText: string | undefined;
  uploading: boolean = false;
  percentLoaded: number = 0;
  documentosCargados: WritableSignal<UploadedFile[]> = signal([]);
  maximoCarga: boolean = false;
  formatInvalid: boolean = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private readonly _tabExamenesService: TabExamenesService,
    private readonly _fichaClinicaStore: FichaClinicaStore,
    private readonly _obtenerArchivosExamenesService: ObtenerArchivosExamenesService
  ) {
    effect(() => {
      if (this.examenesExistentes && this.examenesExistentes().length > 0) {
        this.documentosCargados.set(this.examenesExistentes());
      }
    }, {allowSignalWrites: true});
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {}

  async onFileSelected(event: Event): Promise<void> {
    event.stopPropagation();
    event.preventDefault();
    const input = event.target as HTMLInputElement;
    this.maximoCarga = false;
    this.formatInvalid = false;

    if (input.files) {
      for (let i = 0; i < input.files.length; i++) {
        const file = input.files[i];
        const fileType = file.type;
        const fileSize = file.size / (1024 * 1024);
        if (fileSize > 3) {
          this.maximoCarga = true;
          return;
        }
        if (fileType.startsWith('image/') || fileType === 'application/pdf') {
          // Llamamos a la función asíncrona que manejará la carga del archivo
          await this.readFileAsync(file, fileType).then(() => {
            this.snackBar.openFromComponent(SnackInfoComponent, {
              data: {
                message: 'Archivo cargado con exito.',
                type: SnackInfoType.SUCCESS,
              },
              duration: 2000,
            });
          });
        } else {
          this.formatInvalid = true;
        }
      }
    }
  }

  // Función asíncrona que devuelve una Promesa y espera a que se complete la carga del archivo
  private async readFileAsync(file: File, fileType: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const reader = new FileReader();
      this.uploading = true;

      reader.onprogress = (e) => {
        if (e.lengthComputable) {
          this.percentLoaded = Math.round((e.loaded / e.total) * 100);
        }
      };

      reader.onload = async () => {
        const { idAgendamiento, idPaciente } = this._fichaClinicaStore.values;
        if (!idAgendamiento || !idPaciente) return;

        let dataUpload: UploadedFile = {
          destination: '',
          fileName: '',
        };
        const response = await firstValueFrom(
          this._tabExamenesService.uploadFile(file, idPaciente, idAgendamiento)
        );
        dataUpload = response.data;

        this.selectedImage = reader.result as string;
        this.control.setValue(reader.result as string);
        this.cdr.detectChanges();
        this.documentosCargados.set([
          ...this.documentosCargados(),
          {
            fileName: file.name.trim(),
            destination: dataUpload.destination,
          },
        ]);
        this.hasDocumentUrl = true;
        this.uploading = false;
        resolve(); // Resolvemos la promesa cuando la carga se completa
      };

      reader.onerror = () => {
        reject(new Error('Error al leer el archivo')); // Rechazamos la promesa en caso de error
      };

      if (fileType.startsWith('image/')) {
        reader.readAsDataURL(file);
      } else if (fileType === 'application/pdf') {
        reader.readAsArrayBuffer(file);
        reader.onloadend = () => {
          const base64String = btoa(
            new Uint8Array(reader.result as ArrayBuffer).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ''
            )
          );
          this.selectedImage = `data:application/pdf;base64,${base64String}`;
          this.control.setValue(this.selectedImage);
          this.cdr.detectChanges();
          resolve(); // Resuelve la promesa después de la conversión
        };
      }
    });
  }

  onFileOpen(event: Event, doc: UploadedFile) {
    if (this.isDisabled) {
      return;
    }
    event.stopPropagation();
    event.preventDefault();
    this._obtenerArchivosExamenesService.getArchivosCargados(doc.destination);
  }

  async onFileDelete(event: Event, doc: UploadedFile) {
    if (this.isDisabled) {
      return;
    }

    this._tabExamenesService.deleteFile(doc.destination).subscribe({
      next: (_) => {
        this.documentosCargados.set(
          this.documentosCargados().filter(
            (elem: UploadedFile) => elem.destination !== doc.destination
          )
        );
        this.snackBar.openFromComponent(SnackInfoComponent, {
          data: {
            message: 'Archivo eliminado.',
            type: SnackInfoType.SUCCESS,
          },
          duration: 2000,
        });
      },
    });

    event.stopPropagation();
    event.preventDefault();
    this.cdr.detectChanges();
    this.showDelete = false;
  }

  isControlInvalid(): boolean {
    return this.control.invalid && (this.control.dirty || this.control.touched);
  }
}
